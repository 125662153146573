export default `
<div class="mb-3 d-flex">
<div class="address-autocomplete-container flex-grow-1">
                    <input
                        ref="{{ ctx.ref.searchInput }}"
                        {% for (var attr in ctx.inputAttributes) { %}
                            {{attr}}="{{ctx.inputAttributes[attr]}}"
                        {% } %}
                        value="{{ ctx.displayValue }}"
                        autocomplete="off"
                    >
                    {% if (!ctx.component.disableClearIcon) { %}
                        <i
                            class="address-autocomplete-remove-value-icon fa fa-times"
                            tabindex="{{ ctx.inputAttributes.tabindex }}"
                            ref="{{ ctx.ref.removeValueIcon }}"
                        ></i>
                {% } %}
                </div>
                {% if (ctx.component.enableCurrentPosition) { %}
                            <button class="btn text-primary" type="button" id="btnGetCurrentLocation{{ctx.self.id}}">
                                <i class="fa fa-crosshairs" title="Get current location"></i>
                            </button>     
                    {% } %}
                </div>`;
