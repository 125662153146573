import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LaunchIcon from "@mui/icons-material/Launch";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { FormDataService, FormResponse } from "../../Services/FormDataService";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { ButtonFilled } from "../shared/Buttons";
import { Grid, IconButton } from "@mui/material";
import { createJuvareTheme } from "../shared/JuvareTheme";
import _ from "lodash";
import AppContext, { AppActionTypes, OrgRelation } from "../App/AppContext";

const theme = createJuvareTheme();

const useStyles = makeStyles(() => ({
    accordion: {
        borderRadius: "2px",
    },
    summary: {
        height: 106,
    },
    summarySmall: {
        height: 46,
    },
    summaryTitle: {
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 700,
        maxHeight: "40px",
    },
    summaryLink: {
        color: theme.palette.info.main,
        paddingLeft: "4px",
        cursor: "pointer",
    },
    summaryIcon: {
        color: theme.palette.info.main,
    },
    detail: {
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderTopColor: "grey",
    },
    detailTitle: {
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: 600,
        maxHeight: "40px",
        paddingTop: "5px",
    },
    detailDesc: {
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: 400,
        minHeight: "10px",
        paddingTop: "3px",
        paddingBottom: "10px",
    },
    detailButton: {
        width: 171,
        fontSize: 13,
        lineHeight: "22px",
        fontWeight: 500,
        color: "white",
        height: 40,
        justifyContent: "space-between",
    },
    detailButtonIcon: {
        marginLeft: "5px",
    },
    detailRemove: {
        padding: "2px",
        color: theme.palette.info.main,
        cursor: "pointer",
    },
}));

export default function OrgAccordion() {
    const classes = useStyles();
    const { state, dispatch } = useContext(AppContext);
    const formSvc = new FormDataService();
    const [expanded, setExpanded] = useState<string | undefined>(undefined);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : undefined);
    };

    const removeForm = async (or: OrgRelation, form: FormResponse) => {
        if (state.collatedData) {
            if (await formSvc.removeForm(form)) {
                if (or) {
                    _.remove(or.forms, (f) => f === form);
                    dispatch({ data: [...state.collatedData], type: AppActionTypes.SetData });
                }
            }
        }
    };

    return (
        <div>
            {state.collatedData &&
                state.collatedData
                    .filter((f) => f.forms.filter((ff) => ff.cached === true).length > 0)
                    .map((d) => (
                        <Accordion
                            expanded={expanded === d.org.id}
                            onChange={handleChange(d.org.id)}
                            key={d.org.id}
                            className={classes.accordion}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                className={d.org.name === "unknown" ? classes.summarySmall : classes.summary}
                            >
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography className={classes.summaryTitle}>
                                            {d.org.name === "unknown" ? "Legacy Forms" : d.org.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {d.org.name !== "unknown" && (
                                            <Grid item container direction="row">
                                                <AddCircleIcon className={classes.summaryIcon} />
                                                <Typography sx={{ color: "text.secondary" }}>
                                                    <Link to={`/org/${d.org.id}`} className={classes.summaryLink}>
                                                        Browse forms to add to cache...
                                                    </Link>
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </AccordionSummary>
                            {d.forms &&
                                d.forms.length > 0 &&
                                d.forms
                                    .filter((ff) => ff.cached === true)
                                    .map((f) => (
                                        <AccordionDetails className={classes.detail} key={f.id ?? f.versionId}>
                                            <Grid container direction="column">
                                                <Grid item container justifyContent="space-between">
                                                    <Grid item>
                                                        <Typography className={classes.detailTitle}>
                                                            {f.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            onClick={() => removeForm(d, f)}
                                                            aria-label="delete form"
                                                            role="button"
                                                            name="delete-form"
                                                        >
                                                            <DeleteOutlineIcon className={classes.detailRemove} />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Typography className={classes.detailDesc}>
                                                        {f.description}
                                                    </Typography>
                                                </Grid>
                                                <Grid item container direction="row" justifyContent="space-between">
                                                    <Grid item>
                                                        <Link to={`/forms/${f.id}`}>
                                                            <ButtonFilled className={classes.detailButton}>
                                                                GO TO FORM
                                                                <LaunchIcon className={classes.detailButtonIcon} />
                                                            </ButtonFilled>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    ))}
                        </Accordion>
                    ))}
        </div>
    );
}
