import axios, { AxiosResponse } from "axios";
import commChannel, { commMessages, CommResponse } from "./commChannel";
import sendFormSubmission from "./sendFormSubmission";
import {
    CachedForm,
    FormDataService,
    FormRef,
    FormResponse,
    OfflineFormUpdateMessage,
    SubmissionStatus,
    urlNoCacheOpt,
} from "./Services/FormDataService";
import multicast, { Multicast as MulticastDelegate } from "./Util/MulticastDelegate";
import { BroadcastChannel as bc } from "broadcast-channel";
import sendFileUpload from "./sendFileUpload";

export interface onlineDelegate {
    (status: OnlineStatus): void;
}

export enum OnlineStatus {
    online,
    offline,
    disabled,
}

const isProdAndServiceWorkerAvail: boolean = process.env.NODE_ENV === "production" && "serviceWorker" in navigator; // same condition as in serviceWorkerRegistration.ts

const CompletedSumbissionsRetentionTimeSec = 10; // 10 seconds to display successfully sent submissions on screen before cleaning them.

export class ServiceWorkerHelper {
    cleaning = false;
    processing = false;
    formSvc = new FormDataService();
    online = navigator.onLine === true ? OnlineStatus.online : OnlineStatus.offline;
    private intId: any = undefined;
    private broadcast = new bc(commChannel.Submissions);
    // 40 seconds for development
    interval = 40000;
    private onlineFn?: MulticastDelegate<onlineDelegate>;

    private formUpdateIntlId: any = undefined;
    private formUpdateInterval = 20000;
    private isUpdatingForms = false;

    private static instance: ServiceWorkerHelper;
    private static initialized = false;

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private constructor() {}

    static getInstance = () => {
        if (ServiceWorkerHelper.instance === undefined) {
            ServiceWorkerHelper.instance = new ServiceWorkerHelper();
        }

        return ServiceWorkerHelper.instance;
    };

    init = (onlineFn?: onlineDelegate) => {
        if (onlineFn) {
            if (this.onlineFn) {
                this.onlineFn = multicast(onlineFn, this.onlineFn);
            } else {
                this.onlineFn = multicast(onlineFn);
            }
        }

        if (ServiceWorkerHelper.initialized) {
            return ServiceWorkerHelper.instance;
        }

        if (isProdAndServiceWorkerAvail) {
            this.interval = 300000; // 5 minutes for prod, could go higher
            this.formUpdateInterval = 15 * 60 * 1000; // 15 minutes for prod
        }

        this.broadcast.onmessage = async (event) => {
            if (event && event.type === commMessages.SubmissionAdded) {
                if (this.online === OnlineStatus.online) {
                    this.broadcast.postMessage({ type: commMessages.Processing, subId: event.subId });
                    await this.tryProcess();
                } else {
                    this.broadcast.postMessage({ type: commMessages.Queued, subId: event.subId });
                }
            }
        };

        this.handleNetworkChange(true); // run it immediately now, and it will schedule its next run.

        // Note: this.tryProcess() and this.updateOutdatedForms() will be called from handleNetworkChange() when online status is verified.

        this.cleanup(); // cleanup submissions submitted more than 10 seconds ago now.

        // schedule cleanup of submissions submitted less than 10 seconds ago (in case the page was reloaded immenialtely after submission)
        self.setTimeout(() => {
            this.cleanup();
        }, (CompletedSumbissionsRetentionTimeSec + 1) * 1000);

        ServiceWorkerHelper.initialized = true;

        return ServiceWorkerHelper.instance;
    };

    remove = (onFn: onlineDelegate) => {
        if (this.onlineFn) {
            this.onlineFn.remove(onFn);
        }
    };

    async postBroadcastMessage(msg: any): Promise<void> {
        if (msg && !this.broadcast?.isClosed) {
            return this.broadcast.postMessage(msg);
        }
    }

    private handleNetworkChange = (alwaysReportStatus?: boolean) => {
        if (this.intId) {
            self.clearTimeout(this.intId);
            this.intId = undefined;
        }

        if (alwaysReportStatus && this.onlineFn) {
            try {
                this.onlineFn(this.online);
            } catch (ex) {}
        }

        let changed = false;

        if (this.online !== OnlineStatus.disabled) {
            axios
                .get<string>(`${process.env.REACT_APP_API_BASE_URL?.replace("v1", "")}?${urlNoCacheOpt}`)
                .then((resp) => {
                    if (this.online !== OnlineStatus.online) {
                        changed = true;
                    }
                    this.online = OnlineStatus.online;
                    this.tryProcess();
                    this.updateOutdatedForms();
                })
                .catch((e) => {
                    if (this.online !== OnlineStatus.offline) {
                        changed = true;
                    }
                    this.online = OnlineStatus.offline;
                })
                .finally(() => {
                    if (changed) {
                        if (this.onlineFn) {
                            try {
                                this.onlineFn(this.online);
                            } catch (ex) {}
                        }
                    }

                    if (this.intId) {
                        // it was set again while waiting for GET response.
                        self.clearTimeout(this.intId);
                    }
                    // schedule next run
                    this.intId = self.setTimeout(() => {
                        this.intId = undefined;
                        this.handleNetworkChange(false);
                    }, this.interval);
                });
        }
    };

    isOnline(): boolean {
        return this.online === OnlineStatus.online;
    }

    /**
     * Manually toggles the online state. The interval is reset but will still toggle the state based on connectivity.
     * @param on true to test online, false to test offline.
     */
    testOnline = (status: OnlineStatus) => {
        this.online = status;

        if (this.onlineFn) {
            this.onlineFn(this.online);
        }

        if (this.online === OnlineStatus.online) {
            this.handleNetworkChange(false);
        }
    };

    tryProcess = async () => {
        if (!this.processing) {
            try {
                const process = async () => {
                    await this.processFileUploads();
                    await this.processSubmissions();
                };
                await process();

                // Immediately test if addl records were added during processing
                const data = await this.formSvc.storedFormSubmissions();
                if (data && data.length > 0) {
                    await process();
                }
            } catch (errCount) {
                // TODO: Add error notification
            }
        }
    };

    cleanup = async () => {
        if (!this.cleaning) {
            this.cleaning = true;
            try {
                const now: Date = new Date();
                const data = (await this.formSvc.storedFormSubmissions()).filter((f) => {
                    if (f.status === SubmissionStatus.sent && f.statusDate) {
                        f.statusDate.setSeconds(f.statusDate.getSeconds() + CompletedSumbissionsRetentionTimeSec);
                        return f.statusDate < now;
                    }
                    return false;
                });
                if (data && data.length > 0) {
                    data.map(async (m) => await this.formSvc.removeFormSubmission(m));
                }
                this.broadcast.postMessage({ type: commMessages.Completed });
            } catch (ex) {
                console.error(ex);
            } finally {
                this.cleaning = false;
            }
        }
    };

    processFileUploads = async () => {
        if (this.online !== OnlineStatus.online) {
            return;
        }

        this.processing = true;
        let errCount = 0;
        // There is no need to check if an upload contains this file, the s3 lifecycle will remove any errant uploads
        const files = await this.formSvc.getAllFileUploads();
        if (files && files.length > 0) {
            for (const file of files) {
                const resp = await sendFileUpload(file);
                if (resp.success) {
                    await this.formSvc.deleteFileUpload(file.filename);
                    // TODO: Is this necessary
                    // const msg: CommResponse = { type: commMessages.ProcessedRecord, subId: file.id };
                    // this.broadcast.postMessage(msg);
                } else {
                    errCount++;
                    // TODO: Is this necessary
                    const msg: CommResponse = {
                        type: commMessages.Interrupted,
                        subId: file.filename,
                        message: resp.message,
                    };
                    this.broadcast.postMessage(msg);
                }
            }
        }

        this.processing = false;
        if (errCount > 0) {
            throw errCount;
        } else {
            return;
        }
    };

    processSubmissions = async () => {
        if (this.online !== OnlineStatus.online) {
            return;
        }

        this.processing = true;
        let errCount = 0;
        const data = (await this.formSvc.storedFormSubmissions()).filter((f) => f.status !== SubmissionStatus.sent);
        if (data && data.length > 0) {
            for (const sub of data) {
                const resp = await sendFormSubmission(sub);
                if (resp.success) {
                    sub.statusDate = new Date();
                    sub.status = SubmissionStatus.sent;
                    await this.formSvc.saveFormSubmission(sub);
                    const msg: CommResponse = { type: commMessages.ProcessedRecord, subId: sub.id };
                    this.broadcast.postMessage(msg);
                } else {
                    errCount++;
                    sub.statusDate = new Date();
                    sub.status = SubmissionStatus.error;
                    await this.formSvc.saveFormSubmission(sub);

                    const msg: CommResponse = { type: commMessages.Interrupted, subId: sub.id, message: resp.message };
                    this.broadcast.postMessage(msg);
                }
            }
            self.setTimeout(() => {
                this.cleanup();
            }, (CompletedSumbissionsRetentionTimeSec + 1) * 1000);
        }

        this.processing = false;
        if (errCount > 0) {
            throw errCount;
        } else {
            return;
        }
    };

    /** save the provided form in the local DB and send OfflineFormUpdateMessage notification about it */
    public async saveFormOfflineAndNotify(form: FormResponse): Promise<void> {
        return this.saveFormsOfflineAndNotify([form]);
    }

    /** save the provided array of forms in the local DB and send OfflineFormUpdateMessage notification about it */
    public async saveFormsOfflineAndNotify(forms: FormResponse[]): Promise<void> {
        if (Array.isArray(forms) && forms.length) {
            const formRefs: FormRef[] = [];

            for (let i = 0; i < forms.length; i++) {
                const form: FormResponse = forms[i];
                if (!form) {
                    continue;
                }

                try {
                    const origFormUrl = form.origFormUrl;

                    delete form.origFormUrl; // cleanup before saving it.

                    await this.formSvc.saveForm(form);

                    formRefs.push({
                        formId: form.id,
                        versionId: form.versionId,
                        origFormUrl: origFormUrl,
                    });
                } catch (ex) {
                    console.error(ex);
                }
            }

            if (formRefs.length) {
                const msg: OfflineFormUpdateMessage = {
                    type: commMessages.OfflineFormsUpdated,
                    formRefs: formRefs,
                };

                this.broadcast.postMessage(msg);

                if (isProdAndServiceWorkerAvail) {
                    try {
                        navigator.serviceWorker.controller?.postMessage(msg);
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        }
    }

    /** public method - force immediate form version checks and updates from anywhere for all cached forms or for one with the provided form id. Returns an array IDs of updated forms */
    public updateOutdatedForms: (formId?: string) => Promise<string[]> = async (formId?: string) => {
        if (this.formUpdateIntlId !== undefined) {
            // stop the timer to prevent mutiple form update requests running in parallel and to restart the timer from fresh once we are done here.
            self.clearInterval(this.formUpdateIntlId);
            this.formUpdateIntlId = undefined;
        }

        let result: string[] = [];

        try {
            result = await this.updateOutdatedFormsImpl(formId);
        } finally {
            // restart timer and wait formUpdateInterval before pulling the forms next time because we've just finished the update a moment ago.
            this.formUpdateIntlId = self.setInterval(this.updateOutdatedFormsImpl, this.formUpdateInterval);
        }

        return result;
    };

    /** private method - retrieves form definitions and updates local DB if needed. Returns an array IDs of updated forms. Called from internal setInterval() */
    private updateOutdatedFormsImpl: (formId?: string) => Promise<string[]> = async (formId?: string) => {
        const resultIds: string[] = [];

        if (this.isUpdatingForms || this.online !== OnlineStatus.online) {
            return resultIds;
        }

        this.isUpdatingForms = true;
        try {
            let cached: CachedForm[] = await this.formSvc.storedForms();

            if (formId && cached?.length) {
                cached = cached.filter((f) => f.id === formId);
            }

            if (!cached?.length) {
                return resultIds; // nothing else to do
            }

            // Note: Service workers, Web workers do not have access to the window object.
            //const rootUrl = `${window.location.origin}/forms`;
            const rootUrl = "/forms";

            const ts: number = new Date().getTime(); // to prevent re-using cached versions

            const changedForms: CachedForm[] = [];
            const deletedFormIds: string[] = [];

            const resultPromises: Promise<CachedForm | undefined>[] = cached.map((requestedForm: CachedForm) => {
                const formId: string = requestedForm?.id || "";
                const versionId: string = requestedForm?.versionId || "";
                if (!formId || !versionId) {
                    // cannot retrieve form file without formId, and cannot compare file versions without versionId.
                    return Promise.resolve(undefined);
                }

                const dataFileUrl = `${rootUrl}/${formId}.json?ts=${ts}&${urlNoCacheOpt}`;
                return (async () => {
                    // This function catches all exceptions, never re-throws or rejects the promise.
                    if (this.online !== OnlineStatus.online) {
                        return undefined;
                    }

                    try {
                        const res: AxiosResponse<CachedForm> = await axios.get(dataFileUrl);

                        const retrievedForm: CachedForm | undefined = res?.data;

                        if (retrievedForm) {
                            if (
                                (retrievedForm.id === formId || !retrievedForm.id) && // redundant sanity check, to make sure we retrieved the right json file. Note: some old form files do not have id in the file.
                                retrievedForm.versionId && // version is present in the retrieved file
                                retrievedForm.versionId !== versionId // and the version of the retrieved file does not match the version of the locally cached file.
                            ) {
                                // Note: some old form files do not have id in the file.
                                if (!retrievedForm.id) {
                                    retrievedForm.id = formId;
                                }

                                retrievedForm.origFormUrl = dataFileUrl;

                                changedForms.push(retrievedForm);
                            }
                        } else {
                            // TODO: was it deleted or unpublished, or was it an error???
                            deletedFormIds.push(formId);
                        }
                        return retrievedForm;
                    } catch (ex) {
                        console.error(ex);

                        // TODO: Check the error. If file not found, it was deleted; but in case or a communication error, ignore it here.
                        // deletedFormIds.push(formId); // was it deleted or unpublished???

                        return undefined;
                    }
                })();
            });

            await Promise.all(resultPromises);

            if (changedForms.length) {
                this.saveFormsOfflineAndNotify(changedForms);
            }

            // TODO: The commented code below is for local DB cleanup when the form is no longer available (unpublished or deleted)
            // We need a reliable way to detect if the form was actually unpublished or there was a communication problem with S3 bucket.
            // If case the file was not found, the form was unpublished, so delete if from the local DB as well.
            /*
            if (deletedFormIds.length) {
                deletedFormIds.forEach(async (formId: string) => {
                    await this.formSvc.removeFormById(formId);
                });

                const msg: OfflineFormDeleteMessage = {
                    type: commMessages.OfflineFormsDeleted,
                    formIds: deletedFormIds,
                };

                this.broadcast.postMessage(msg);

                if (isProdAndServiceWorkerAvail) {
                    try {
                        navigator.serviceWorker.controller?.postMessage(msg);
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
            */
        } catch (ex) {
            console.error(ex);
        } finally {
            this.isUpdatingForms = false;
        }
        return resultIds;
    };
}
