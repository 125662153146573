const commChannel = {
    Submissions: "submissions",
};

export const commMessages = {
    /** Submission added to the database */
    SubmissionAdded: "subAdded",
    /** App is offline and message will be processed when online. */
    Queued: "queued",
    /** App is online and processing messages */
    Processing: "processing",
    /** Processing has been completed */
    Completed: "completed",
    /** During processing, the app went offline. Will re-attempt when online. */
    Interrupted: "interrupted",
    /** Message successfully sent */
    ProcessedRecord: "processed",
    /** Tells the service worker helper to pause */
    Pause: "pause",
    /** Tells the service worker helper to continue */
    Continue: "continue",
    /** Some of Offline Forms have been updated with newer versions. */
    OfflineFormsUpdated: "OfflineFormsUpdated",
    /** Some of Offline Forms have been deleted because they were not found on the server anymore. */
    OfflineFormsDeleted: "OfflineFormsDeleted",
    /** Indicates a form has been cached/removed */
    FormCacheChanged: "formCacheChanged",
    /** Indicates a new version of worker is installed, the user should choose to reload or not */
    AppVersionUpdated: "appVersionUpdated",
    /** There is a problem connecting to the database */
    DbConnectionError: "dbConnectionError",
    /** The page was forcibly reloaded after app version upgrade */
    PageReloadedAfterUpgrade: "pageReloadedAfterUpgrade",
};

export interface CommResponse {
    type: string;
    subId?: string;
    message?: string | Error;
}

export default commChannel;
