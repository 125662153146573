import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useContext, useEffect } from "react";
import AppContext, { AppActionTypes } from "../App/AppContext";
import HeadSection from "../HeadSection/HeadSection";
import OrgAcccordion from "./OrgAccordion";

const useStyles = makeStyles(() => ({
    contentGrid: {
        paddingLeft: "16px",
        paddingRight: "16px",
    },
}));

export default function Home() {
    const classes = useStyles();
    const { dispatch } = useContext(AppContext);

    useEffect(() => {
        dispatch({ type: AppActionTypes.SetTitle, title: "Juvare Forms" });
    }, []);

    return (
        <Grid container direction={"column"}>
            <Grid item xs={12} md={6}>
                <HeadSection />
            </Grid>
            <Grid item xs={12} md={6} className={classes.contentGrid}>
                <Typography sx={{ mt: 2, mb: 2 }} variant="h6" component="div">
                    My cached forms
                </Typography>
                <OrgAcccordion />
            </Grid>
        </Grid>
    );
}
