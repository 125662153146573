import axios from "axios";
import { FormSubmissionData } from "./Services/FormDataService";
import { SubmissionResponse } from "./SubmissionResponse";

const sendFormSubmission = async (sub: FormSubmissionData): Promise<SubmissionResponse> => {
    const url = process.env.REACT_APP_SUBMIT_DESTINATION_URL;

    const resp: SubmissionResponse = {
        success: false,
    };

    if (url) {
        try {
            await axios.post(url, sub);
            console.log("Submitted successfully");
            resp.success = true;
        } catch (err: any) {
            console.error("failed to send submission", err);
            resp.message = err.toString();
        }
    } else {
        resp.message = "URL for form submission is not defined";
        console.error(resp.message);
    }

    return resp;
};

export default sendFormSubmission;
