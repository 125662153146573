import React, { useCallback, useEffect, useRef } from "react";
import "./App.css";
import Renderer from "../Renderer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../Home";
import OrgPage from "../Organization/OrgPage";
import AppContext, { AppActionTypes, appReducer, getOrg, OrgRelation } from "./AppContext";
import { Grid } from "@mui/material";
import Header from "../Header";
import { FormDataService } from "../../Services/FormDataService";
import Submissions from "../Submissions";
import commChannel, { commMessages } from "../../commChannel";
import { BroadcastChannel as bc } from "broadcast-channel";

const App: React.FC = () => {
    const broadcast = useRef(new bc(commChannel.Submissions));
    const [state, dispatch] = React.useReducer(appReducer, {
        collatedData: [],
        title: "Juvare Forms",
    });

    const collateData = useCallback(async () => {
        const data: OrgRelation[] = [];
        const formSvc = new FormDataService();
        const forms = await formSvc.storedForms();

        if (forms && forms.length > 0) {
            for (const frm of forms) {
                let org = data.find((f) => f.org.id === frm.organization_id);
                if (!org) {
                    try {
                        org = await getOrg(frm.organization_id);
                        data.push(org);
                    } catch (err) {
                        console.log(err);
                    }
                }
                org?.forms.push({ ...frm, cached: true });
            }
        }

        return data;
    }, []);

    useEffect(() => {
        let disposing = false;
        collateData().then((data) => {
            if (!disposing) {
                dispatch({ data, type: AppActionTypes.SetData });
            }
        });
        return () => {
            disposing = true;
            broadcast.current.close();
        };
    }, []);

    broadcast.current.onmessage = (event: { type: keyof typeof commMessages }) => {
        switch (event.type) {
            case commMessages.FormCacheChanged: {
                collateData().then((data) => {
                    dispatch({ data, type: AppActionTypes.SetData });
                });
                break;
            }
        }
    };

    return (
        <AppContext.Provider value={{ state: state, dispatch }}>
            <div className="App">
                <BrowserRouter>
                    <Grid container direction={"column"}>
                        <Grid item>
                            <Header title={"Juvare Forms"} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/org/:id" element={<OrgPage />} />
                                <Route path="/forms/:id" element={<Renderer />} />
                                <Route path="/:id" element={<Renderer />} />
                                <Route path="/sub" element={<Submissions />} />
                            </Routes>
                        </Grid>
                    </Grid>
                </BrowserRouter>
            </div>
        </AppContext.Provider>
    );
};

export default App;
