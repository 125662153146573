import React from "react";

export const JuvareLogo: React.FC = () => {
    return (
        <svg viewBox="0 0 133 207.3" width={15} height={33}>
            <g>
                <path
                    style={{ fill: "#EA0029" }}
                    d="M44,69.5v68.2v7.4V163l0,0c-24.3-0.1-44-19.9-44-44.2V74.6V44.1h44v25.2V69.5z"
                />
                <path
                    style={{ fill: "#EA0029" }}
                    d="M88,0H44v44.2h44v30.5v44.2c0,24.3-19.7,44.1-44,44.2v44.2c48.7-0.1,88.2-39.6,88.2-88.4V74.7V0H88z"
                />
            </g>
        </svg>
    );
};
