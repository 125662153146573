import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
    AppBar,
    Box,
    IconButton,
    Toolbar,
    Typography,
    Menu,
    MenuItem,
    Tooltip,
    Snackbar,
    Alert,
    Button,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { ServiceWorkerHelper, OnlineStatus } from "../../ServiceWorkerHelper";
import { useNavigate } from "react-router-dom";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import AppContext, { formCached } from "../App/AppContext";
import Badge from "@mui/material/Badge";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { OrganizationDataService } from "../../Services/OrganizationDataService";
import { CachedForm, FormDataService } from "../../Services/FormDataService";
import commChannel, { commMessages } from "../../commChannel";
import { BroadcastChannel as bc } from "broadcast-channel";

export interface HeaderProps {
    title: string;
}

const useStyles = makeStyles(() => ({
    appBar: {
        backgroundColor: "#FFFFFF",
        color: "#2D2A26",
        boxShadow: "none",
    },
    logoLink: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
    },
    logoLinkImg: {
        width: "150px",
        height: "25px",
    },
    accountCircle: {
        fill: "black",
    },
    online: {
        color: "green",
        backgroundColor: "white",
    },
    offline: {
        color: "red",
        backgroundColor: "white",
    },
    disabled: {
        color: "black",
        backgroundColor: "white",
    },
    formCached: {
        color: "green",
    },
    formNotCached: {
        color: "grey",
    },
}));

const orgSvc = new OrganizationDataService();
const formSvc = new FormDataService();

const getWaitingServiceWorker: () => Promise<ServiceWorker | null | undefined> = async () => {
    if (navigator?.serviceWorker) {
        const registration = await navigator.serviceWorker.getRegistration();
        return registration?.waiting;
    }
};

const Header: React.FC<HeaderProps> = (props) => {
    const [online, setOnline] = React.useState<OnlineStatus>(
        navigator.onLine === true ? OnlineStatus.online : OnlineStatus.offline,
    );
    const helper = useRef(ServiceWorkerHelper.getInstance().init((on) => setOnline(on)));
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AppContext);
    const [cached, setCached] = useState(false);
    const dev = process.env.REACT_APP_STAGE !== "prod";
    const [showSnack, setShowSnack] = React.useState<boolean>(false);
    const [showAppUpdate, setShowAppUpdate] = React.useState<boolean>(false);
    const [mustUpdateApp, setMustUpdateApp] = React.useState<boolean>(false);
    const [isPageReloadedAfterUpgrade, setIsPageReloadedAfterUpgrade] = React.useState<boolean>(false);

    //const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const broadcast = useRef<bc>();

    const toggleOnline = (status: OnlineStatus) => {
        helper.current.testOnline(status);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (nav: string) => {
        setAnchorEl(null);
        navigate(nav);
    };

    const forceAppUpdate = async () => {
        setShowAppUpdate(false);
        setMustUpdateApp(false);

        if (navigator?.serviceWorker) {
            const waitingSW = await getWaitingServiceWorker();
            if (waitingSW) {
                waitingSW.postMessage("skipWaiting");
            }
        } else {
            console.log("Force Update was called, but the browser is in localhost");
        }
    };

    useEffect(() => {
        if (!broadcast.current) {
            broadcast.current = new bc(commChannel.Submissions);
        }
        const bcRef: bc = broadcast.current;
        bcRef.onmessage = (msg) => {
            if (!msg) {
                return; // so you don't have to check if msg is not null all the time.
            }

            switch (msg.type) {
                case commMessages.AppVersionUpdated:
                    setShowAppUpdate(true);
                    if (msg.mustUpdate) {
                        setMustUpdateApp(true);
                    }
                    break;

                case commMessages.DbConnectionError:
                    if (msg.message) {
                        //setErrorMessage(msg.message);
                        setMustUpdateApp(true);
                        formSvc.closeDb();
                        orgSvc.closeDb();
                    }
                    break;

                case commMessages.PageReloadedAfterUpgrade:
                    setIsPageReloadedAfterUpgrade(true);
                    break;
            }
        };

        const swEvHandler = (ev: MessageEvent) => {
            if (ev.origin === window.location.origin) {
                if (ev.data?.type === commMessages.PageReloadedAfterUpgrade) {
                    setIsPageReloadedAfterUpgrade(true);
                }
            }
        };

        window.navigator.serviceWorker?.addEventListener("message", swEvHandler);

        return () => {
            window.navigator.serviceWorker?.removeEventListener("message", swEvHandler);

            bcRef.close();
            if (bcRef === broadcast.current) {
                broadcast.current = undefined;
            }
        };
    }, []);

    const updateCached = useCallback(async () => {
        if (state.currentForm) {
            setCached(await formCached(state.currentForm.id));
        } else {
            setCached(false);
        }
    }, [state]);

    useEffect(() => {
        updateCached();
    }, [state.currentForm, updateCached]);

    useEffect(() => {
        document.title = state.title;
    }, [state.title]);

    const saveOrg = async (orgId: string) => {
        let org = await orgSvc.getStoredOrg(orgId);
        if (!org) {
            org = await orgSvc.getOrgById(orgId);
            if (org) {
                orgSvc.saveOrg(org);
            }
        }
    };

    const removeForm = async () => {
        const currentForm: CachedForm | undefined = state?.currentForm;

        if (currentForm) {
            await formSvc.removeForm(currentForm);

            broadcast.current?.postMessage({ type: commMessages.FormCacheChanged });
        }
    };

    const saveForm = async () => {
        const currentForm: CachedForm | undefined = state?.currentForm;
        if (currentForm) {
            if (await formSvc.saveForm(currentForm)) {
                if (currentForm.organization_id) {
                    await saveOrg(currentForm.organization_id);
                }

                broadcast.current?.postMessage({ type: commMessages.FormCacheChanged });
            }
        }
    };

    const toggleCached = async () => {
        if (cached) {
            await removeForm();
        } else {
            await saveForm();
            setShowSnack(true);
        }
        await updateCached();
    };

    const handleCloseSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setShowSnack(false);
    };

    const handleCloseAppUpdateSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway" || mustUpdateApp) {
            return;
        }

        setShowAppUpdate(false);
    };

    const handleClosePageReloadSnack = (event?: React.SyntheticEvent | Event, reason?: string) => {
        setIsPageReloadedAfterUpgrade(false);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {state.title}
                    </Typography>
                    {state && state.currentForm && (
                        <Tooltip
                            title={
                                cached === true
                                    ? "This form has been cached for offline use"
                                    : "This form is not yet cached"
                            }
                        >
                            <IconButton
                                edge="start"
                                aria-label="save"
                                className={cached === true ? classes.formCached : classes.formNotCached}
                                onClick={toggleCached}
                            >
                                <SaveAltIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {dev && (
                        <FormControlLabel
                            value="bottom"
                            control={
                                <Switch
                                    className={
                                        online === OnlineStatus.online
                                            ? classes.online
                                            : online === OnlineStatus.offline
                                            ? classes.offline
                                            : classes.disabled
                                    }
                                    checked={
                                        online === OnlineStatus.online
                                            ? true
                                            : online === OnlineStatus.offline
                                            ? false
                                            : false
                                    }
                                    size="small"
                                />
                            }
                            label={
                                online === OnlineStatus.online
                                    ? "online"
                                    : online === OnlineStatus.offline
                                    ? "offline"
                                    : "disabled"
                            }
                            labelPlacement="bottom"
                            onClick={() =>
                                toggleOnline(
                                    online === OnlineStatus.online
                                        ? OnlineStatus.offline
                                        : online === OnlineStatus.offline
                                        ? OnlineStatus.disabled
                                        : OnlineStatus.online,
                                )
                            }
                        />
                    )}
                    <IconButton
                        size="large"
                        edge="end"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleClick}
                        data-testid="menu-button"
                    >
                        <Badge
                            color={
                                online === OnlineStatus.online
                                    ? "info"
                                    : online === OnlineStatus.offline
                                    ? "secondary"
                                    : "warning"
                            }
                            variant="dot"
                        >
                            <MenuOpenIcon />
                        </Badge>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button",
                        }}
                    >
                        <MenuItem onClick={() => handleClose("/")}>Home</MenuItem>
                        <MenuItem onClick={() => handleClose("/sub")}>Submissions</MenuItem>
                        {dev && (
                            <MenuItem onClick={() => setShowAppUpdate(!showAppUpdate)}>Toggle Show Update</MenuItem>
                        )}
                    </Menu>
                </Toolbar>
            </AppBar>
            {showSnack && (
                <Snackbar
                    open={true}
                    autoHideDuration={6000}
                    onClose={handleCloseSnack}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert onClose={handleCloseSnack} severity="info" sx={{ width: "100%" }}>
                        Form has been saved for offline use
                    </Alert>
                </Snackbar>
            )}
            {showAppUpdate && (
                <Snackbar
                    open={true}
                    onClose={handleCloseAppUpdateSnack}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert severity={mustUpdateApp ? "warning" : "info"} sx={{ width: "100%" }}>
                        An update has been downloaded,{" "}
                        {mustUpdateApp ? "you must update to be able to continue" : "would you like to load it now?"}
                        <Button color="secondary" size="small" onClick={forceAppUpdate}>
                            Force Update
                        </Button>
                        {!mustUpdateApp && (
                            <Button
                                color="secondary"
                                size="small"
                                onClick={() => {
                                    setShowAppUpdate(false);
                                }}
                            >
                                Ignore
                            </Button>
                        )}
                    </Alert>
                </Snackbar>
            )}
            {isPageReloadedAfterUpgrade && (
                <Snackbar
                    open={true}
                    onClose={handleClosePageReloadSnack}
                    autoHideDuration={6000}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert onClose={handleClosePageReloadSnack} severity="info" sx={{ width: "100%" }}>
                        This page has been automatically reloaded after version upgrade.
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default Header;
