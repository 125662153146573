import { Paper } from "@mui/material";
import React from "react";
import { gradients } from "./colors";

interface Props {
    gradient: keyof typeof gradients;
}

export const ColorPaper: React.FC<Props> = (props) => {
    return (
        <Paper
            elevation={0}
            sx={{
                ...gradients[props.gradient],
                borderRadius: 0,
            }}
        >
            {props.children}
        </Paper>
    );
};
