import { ButtonProps, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { FC } from "react";
import clsx from "clsx";
import { darken } from "polished";
import { createJuvareTheme } from "../JuvareTheme";
// import { createJuvareTheme } from "@juvare/common-web-ui/JuvareTheme";

const theme = createJuvareTheme();

const useStyles = makeStyles(() => ({
    root: (props: { bgColor?: string; color?: string }) => ({
        margin: `0 0 0 ${theme.spacing(1)}px`,
        padding: "4px 10px 4px 10px",
        color: props.color ?? theme.palette.primary.main,
        borderWidth: "2px !important",
        backgroundColor: props.bgColor ?? theme.palette.primary.main,
        "&:hover": {
            backgroundColor: props.bgColor
                ? darken(0.07, props.bgColor)
                : `${darken(0.07, theme.palette.primary.main as string)}`,
        },
        "& .Mui-disabled": {
            backgroundColor: props.bgColor
                ? darken(0.07, props.bgColor)
                : `${darken(0.07, theme.palette.primary.main as string)}`,
            opacity: 0.7,
        },
    }),
}));

type ButtonFilledProps = ButtonProps & { bgColor?: string; color?: string };
export const ButtonFilled: FC<ButtonFilledProps> = ({ bgColor, color, ...rest }: ButtonFilledProps) => {
    const { root } = useStyles({ bgColor: bgColor, color: color });

    return (
        <Button
            role="button"
            variant="contained"
            data-testid="filled-button"
            {...rest}
            className={clsx(root, rest.className)}
        />
    );
};
