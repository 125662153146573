/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Templates } from "formiojs";
import { Components } from "@formio/react";
import template from "./template";
import { ServiceWorkerHelper } from "../../../../ServiceWorkerHelper";
import { OnlineStatus } from "../../../../ServiceWorkerHelper";

const Field = Components.components.field;
const AddressComponent = Components.components.address;
const ComponentForm = Components.components.address.editForm;

interface MapQuestAddressResponse {
    displayString: string;
    trueDisplayString: string;
    apiLatLng: string;
    city?: string;
    stateCode?: string;
    postalCode?: string;
    county?: string;
    countryCode?: string;
    street?: string;
    coordinates?: {
        latitude: number;
        longitude: number;
    };
}

interface MapQuestRequestOptions {
    url: string;
    params: {
        key: string;
        siteKey: string;
        limit: number;
        collection: string;
        countryCode?: string;
    };
    queryProperty: string;
    responseProperty: string;
    displayValueProperty: string;
}

export function editForm(...extend: any[]) {
    return ComponentForm(
        [
            {
                key: "display",
                components: [
                    {
                        key: "enableManualMode",
                        ignore: true,
                    },
                    {
                        type: "checkbox",
                        label: "Show Get Current Position Icon",
                        key: "enableCurrentPosition",
                        input: true,
                        tooltip:
                            "Displays the icon to the right of the input box which allows a user to get their current position",
                        defaultValue: true,
                    },
                ],
            },
            {
                key: "data",
                components: [
                    {
                        key: "multiple",
                        ignore: true,
                    },
                    {
                        key: "defaultValue",
                        ignore: true,
                    },
                    {
                        key: "siteKey",
                        defaultValue: "ca9RmJ4ZxghDHdJsyqUc1Y7LeLFqz5AS", //process.env.REACT_APP_MAPQUEST_SITEKEY,
                        input: true,
                        hidden: true,
                        clearOnHide: false,
                    },
                ],
            },
            {
                key: "provider",
                ignore: true,
            },
            {
                key: "validation",
                components: [
                    {
                        type: "checkbox",
                        label: "Strict Validation",
                        key: "strictValidation",
                        input: true,
                        weight: 1,
                        tooltip: "Value must be a real address",
                        defaultValue: true,
                    },
                ],
            },
            {
                key: "mapQuestOptions",
                label: "MapQuest Options",
                components: [
                    {
                        type: "textfield",
                        label: "Country Code",
                        key: "countryCode",
                        input: true,
                        weight: 1,
                        placeholder: "US,LT",
                        tooltip: "Enter the Alpha-2 country codes comma-delimited list to search over.",
                        customDefaultValue: () => undefined,
                        validate: {
                            pattern: "^[A-Z]{2}((,)[A-Z]{2})*$",
                        },
                    },
                ],
            },
        ],
        ...extend,
    );
}

export class MapQuestAddressComponent extends (AddressComponent as any) {
    public trueDisplayString = "";
    public trueSearchInput: any;
    private helper: ServiceWorkerHelper = ServiceWorkerHelper.getInstance();
    private siteKey = process.env.REACT_APP_MAPQUEST_SITEKEY;

    constructor(component: any, options: any, data: any) {
        super(component, options, data);
        Templates.current.address.form = template;
        component.skipRemoveConfirm = true;
    }

    static schema(...extend: any[]) {
        return AddressComponent.schema(
            {
                type: "MapQuestAddressComponent",
                label: "Address",
                key: "MapQuestAddress",
                input: true,
                provider: "custom",
                hideLabel: false,
                enableManualMode: false,
                isNew: true,
            },
            ...extend,
        );
    }

    static get builderInfo() {
        return {
            title: "Address",
            group: "Advanced",
            icon: "home",
            weight: 100,
            documentation: "",
            schema: MapQuestAddressComponent.schema(),
        };
    }

    get defaultSchema() {
        return MapQuestAddressComponent.schema();
    }

    checkComponentValidity(data: any, dirty?: any, rowData?: any, silentCheck?: boolean | any): boolean {
        let isValid = super.checkComponentValidity.apply(this, arguments);
        if (isValid) {
            isValid = this.checkAddressShowMessage(data[this.path]);
        }
        return isValid;
    }

    private isAddressValid(addrObj: any): boolean {
        if (addrObj && addrObj.displayString && addrObj.coordinates) {
            return true;
        } else if (this.helper.online !== OnlineStatus.online) {
            return true;
        }
        return false;
    }

    private checkAddressShowMessage(addrObj: any): boolean {
        let errorMsg: string | undefined = undefined;
        let warnMsg: string | undefined = undefined;

        const isEmpty = !(addrObj && (addrObj.displayString || addrObj.trueDisplayString || "").trim());
        const isRequired = this.component.validate?.required;

        if (isEmpty) {
            if (isRequired) {
                errorMsg = `${this.label} is required`;
            }
        } else {
            if (this.component.strictValidation) {
                if (!this.isAddressValid(addrObj)) {
                    errorMsg = `The provided address could not be found`;
                } else {
                    if (this.helper.online !== OnlineStatus.online) {
                        warnMsg = `Address cannot be validated offline`;
                    }
                }
            }
        }

        if (errorMsg) {
            this.setComponentValidity([{ message: errorMsg, level: "error" }], true, false);
        } else if (warnMsg) {
            this.setComponentValidity([{ message: warnMsg, level: "warn" }], true, false);
        }

        return !errorMsg;
    }

    public static editForm = editForm;

    errorGettingPosition(error: GeolocationPositionError) {
        switch (error.code) {
            case GeolocationPositionError.PERMISSION_DENIED:
                alert("User denied the request for Geolocation.");
                break;
            case GeolocationPositionError.POSITION_UNAVAILABLE:
                alert("Location information is unavailable.");
                break;
            case GeolocationPositionError.TIMEOUT:
                alert("The request to get user location timed out.");
                break;
            default:
                alert("An unknown error occurred.");
                break;
        }
    }

    successGettingPosition = (pos: GeolocationPosition) => {
        const { longitude, latitude } = pos.coords;
        this.longitude = longitude;
        this.latitude = latitude;
        // temporarily fill in known values
        this.displayValue = `POINT(${longitude} ${latitude})`;
        this.address.displayString = `POINT(${longitude} ${latitude})`;
        this.trueDisplayString = `POINT(${longitude} ${latitude})`;
        this.dataValue = {
            displayString: this.trueDisplayString,
            coordinates: {
                longitude: longitude,
                latitude: latitude,
            },
        };
        this.redraw(); //this makes the clear icon show up
    };

    async getReverseGeocode() {
        const {
            latitude,
            longitude,
            component: { siteKey },
        } = this;

        const response = await window.fetch(
            `https://www.mapquestapi.com/geocoding/v1/reverse?key=${this.siteKey}&location=${latitude},${longitude}&includeRoadMetadata=true&includeNearestIntersection=true`,
        );

        return await response.json();
    }

    async getCurrentLocation() {
        return new Promise(
            (resolve: (value: GeolocationPosition) => void, reject: (reason: GeolocationPositionError) => void) => {
                window.navigator.geolocation.getCurrentPosition(resolve, reject, {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                });
            },
        );
    }

    processReverseGeocodeResults(data: any) {
        if (data?.results.length >= 1) {
            if (data.results[0]?.locations.length >= 1) {
                const rootObj = data.results[0].locations[0];
                this.address.displayString = `${
                    rootObj.street
                }, ${MapQuestAddressComponent.getAdminAreaValueFromMapQuestResponse(
                    rootObj,
                    "City",
                )}, ${MapQuestAddressComponent.getAdminAreaValueFromMapQuestResponse(rootObj, "State")} ${
                    rootObj.postalCode
                }`;
                this.displayValue = this.address.displayString;
                this.trueDisplayString = this.address.displayString;
                this.dataValue.city = MapQuestAddressComponent.getAdminAreaValueFromMapQuestResponse(rootObj, "City");
                this.dataValue.stateCode = MapQuestAddressComponent.getAdminAreaValueFromMapQuestResponse(
                    rootObj,
                    "State",
                );
                this.dataValue.postalCode = rootObj.postalCode;
                this.dataValue.county = MapQuestAddressComponent.getAdminAreaValueFromMapQuestResponse(
                    rootObj,
                    "County",
                );
                this.dataValue.countryCode = MapQuestAddressComponent.getAdminAreaValueFromMapQuestResponse(
                    rootObj,
                    "Country",
                );
                this.dataValue.street = rootObj.street;
            }
        }
    }

    initializeProvider() {
        return super.initializeProvider(this.component.provider, this.getMapQuestOptions(this.component.countryCode));
    }

    normalizeAddress(value: any): MapQuestAddressResponse {
        const normalizedValue: any = {};
        if ((value?.displayString || "").trim()) {
            normalizedValue.displayString = value.displayString.trim();
        }

        const geometry = value?.place?.geometry;
        const properties = value?.place?.properties;
        if (geometry && geometry?.type?.toLowerCase() === "point") {
            normalizedValue.coordinates = {
                longitude: geometry.coordinates[0],
                latitude: geometry.coordinates[1],
            };
        }
        if (properties) {
            for (const [key, value] of Object.entries(properties)) {
                if (key.toLowerCase() !== "type") {
                    normalizedValue[key] = value;
                }
            }
        }
        return normalizedValue;
    }

    get address() {
        if (this.searchInput && this.searchInput.length === 1) {
            this.trueDisplayString = this.searchInput[0].value;
        }
        return this.dataValue;
    }

    set address(value: any) {
        this.dataValue = this.normalizeAddress(value);
        super.redraw();
    }

    get county() {
        return this?.address?.county || "";
    }

    get countyLabel() {
        return this?.address?.stateCode === "LA" ? "Parish" : "County";
    }

    attach(element: any) {
        super.attach(element);
        const result = (this.builderMode || this.manualMode ? super.attach : Field.prototype.attach).call(
            this,
            element,
        );
        const locationButton = document.getElementById("btnGetCurrentLocation" + this.id);
        if (locationButton) {
            locationButton.addEventListener("click", () => this.handleLocationButtonClick());
        }
        this.searchInput.forEach((element: any, index: any) => {
            this.trueSearchInput = element;
            this.removeEventListener(element, "blur");
            this.addEventListener(element, "blur", () => {
                if (!element) {
                    this.clearAddressData();
                } else if (element.value) {
                    let addressToParse = this.isMultiple ? this.address[index] : this.address;
                    if (
                        (element.value || "").trim().toLowerCase() !==
                        (this.address?.displayString || "").trim().toLowerCase()
                    ) {
                        this.clearAddressData();
                        const manualAddress: { [key: string]: string } = {};
                        const mapQuestOptions = this.getMapQuestOptions(this.component?.countryCode);
                        this.dataValue.trueDisplayString = element.value;
                        manualAddress[mapQuestOptions.displayValueProperty] = element.value;
                        addressToParse = manualAddress;
                    }
                    element.value = this.getDisplayValue(addressToParse);
                }

                this.checkAddressShowMessage(this.dataValue);
            });
        });
        return result;
    }

    async handleLocationButtonClick() {
        let position: GeolocationPosition | null = null;
        try {
            position = await this.getCurrentLocation();
        } catch (ex) {
            this.errorGettingPosition(ex as GeolocationPositionError);
        }

        if (position) {
            this.successGettingPosition(position);
            const geocodeResult = await this.getReverseGeocode();
            this.processReverseGeocodeResults(geocodeResult);
        }
        // update the display
        this.redraw();
    }

    getMapQuestOptions(countryCode?: string): MapQuestRequestOptions {
        // Mapquest API doc: https://developer.mapquest.com/documentation/searchahead-api/get/
        const settings: MapQuestRequestOptions = {
            url: "https://www.mapquestapi.com/search/v3/prediction",
            params: {
                key: this.siteKey ?? "",
                siteKey: "", // note: siteKey vlaue is provided in the 'key' query param above
                limit: 10,
                collection: "address",
            },
            queryProperty: "q",
            responseProperty: "results",
            displayValueProperty: "displayString",
        };
        if (countryCode) {
            settings.params.countryCode = countryCode;
        }
        delete (settings.params as any).siteKey; // cleanup. siteKey is not needed for this API, it is provided in the 'key' query param
        return settings;
    }

    static getAdminAreaValueFromMapQuestResponse(data: any, keyName: string): string {
        if (data) {
            const adminAreaTypeKeys = Object.keys(data).filter((key) => {
                const matches = key.match(/adminArea\d*Type/g);
                return matches?.length;
            });

            const keyNameKeyMatch = adminAreaTypeKeys.find((key) => data[key] === keyName)?.replace(/Type$/, "") ?? "";
            return data[keyNameKeyMatch] ?? "";
        }
        return "";
    }

    private clearAddressData() {
        this.dataValue.displayString = "";
        this.dataValue.city = "";
        this.dataValue.stateCode = "";
        this.dataValue.postalCode = "";
        this.dataValue.county = "";
        this.dataValue.countryCode = "";
        this.dataValue.street = "";
        this.dataValue.coordinates = null;
    }
}

export default MapQuestAddressComponent;
