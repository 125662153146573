/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import React from "react";
import ReactDOM from "react-dom";
import { ReactComponent } from "@formio/react";
import settingsForm from "./Recaptcha.editForm";
import ReCAPTCHA from "react-google-recaptcha";
import { OnlineStatus, ServiceWorkerHelper } from "../../../../ServiceWorkerHelper";

export default class Recaptcha extends ReactComponent {
    private helper: ServiceWorkerHelper = ServiceWorkerHelper.getInstance();

    static get builderInfo() {
        return {
            title: "Google reCAPTCHA v2",
            icon: "refresh",
            group: "Security",
            documentation: "https://www.google.com/recaptcha/about/",
            weight: 10,
            schema: Recaptcha.schema(),
        };
    }

    /**
     * Available schema fields: https://github.com/formio/formio.js/blob/master/src/components/_classes/component/Component.js
     * Set a unique type to use later in Components.setComponent('type', MyComponent)
     */
    static schema() {
        return ReactComponent.schema({
            type: "recaptchaCustom",
            placeholder: "recaptcha",
            input: true,
        });
    }

    static editForm = settingsForm;

    attachReact(element: Element | DocumentFragment | null) {
        const onlineStatus = this.helper.online;

        ReactDOM.render(
            <div id="captcha-container" className={onlineStatus === OnlineStatus.offline ? "warn" : ""}>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY ? process.env.REACT_APP_RECAPTCHA_SITEKEY : ""}
                    onChange={(token) => this.updateValue(token, null)}
                />
                {onlineStatus === OnlineStatus.offline && (
                    <div style={{ color: "red" }}>Unable to verify captcha while offline.</div>
                )}
            </div>,
            element,
        );
    }

    detachReact(element: Element | DocumentFragment | null): void {
        if (element) {
            ReactDOM.unmountComponentAtNode(element);
        }
    }

    checkValidity(data: any, dirty?: any, rowData?: any) {
        const valid = super.checkValidity(data, dirty, rowData);
        if (!valid) {
            return false;
        }
        return this.validate(data, dirty, rowData);
    }

    setCustomValidity(messages: any[] | string, dirty: boolean, silentCheck: boolean) {
        return super.setCustomValidity(messages, dirty, silentCheck);
    }

    validate(data: any, dirty: any, rowData: any) {
        const onlineStatus = this.helper.online;

        if (!this.dataValue && this.visible && dirty && onlineStatus === OnlineStatus.online) {
            this.setCustomValidity("Please verify that you are not a robot.", dirty, false);
            return false;
        }

        return true;
    }
}
