import { OrganizationResponse } from "../Services/OrganizationDataService";

export const DataStores = {
    Organizations: "orgs",
    Forms: "forms",
    Submissions: "form-submissions",
    FileUploads: "files",
    Settings: "settings",
};

export const StoreIndices = {
    formByOrgId: "forms-orgId",
    submissionsByFormId: "subs-formId",
    formsByVersion: "forms-version",
    filesBySubId: "files-subId",
};

export interface OrganizationListModel {
    organizations: OrganizationResponse[];
}
