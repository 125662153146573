/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import baseEditForm from "formiojs/components/_classes/component/Component.form";

export default (...extend: any[]) => {
    return baseEditForm(
        [
            {
                key: "display",
                ignore: true,
            },
            {
                key: "data",
                ignore: true,
            },
            {
                key: "validation",
                ignore: true,
            },
            {
                key: "conditional",
                ignore: false,
            },
            {
                key: "logic",
                ignore: true,
            },
            {
                key: "api",
                ignore: true,
            },
            {
                key: "layout",
                ignore: true,
            },
        ],
        ...extend,
    );
};
