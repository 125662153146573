import { Box, Typography } from "@mui/material";
import React from "react";
import { JuvareLogo } from "../JuvareLogo";

export const JuvareLogoFull: React.FC = () => {
    return (
        <Typography
            sx={{ display: "inline-flex", alignItems: "center", cursor: "pointer" }}
            component="div"
            onClick={() => window.open("https://juvare.com", "_blank")}
        >
            <Box
                component={"span"}
                sx={{
                    marginRight: "6px",
                    fontSize: 12,
                }}
            >
                Powered by
            </Box>
            <JuvareLogo />
            <Box
                component={"span"}
                sx={{
                    marginLeft: "6px",
                    fontSize: 12,
                    textTransform: "uppercase",
                }}
            >
                Juvare
            </Box>
        </Typography>
    );
};
