import React from "react";
import { CachedForm, FormDataService } from "../../Services/FormDataService";
import { OrganizationDataService, OrganizationResponse } from "../../Services/OrganizationDataService";
import { validate as uuidValidate } from "uuid";

export interface OrgRelation {
    org: OrganizationResponse;
    forms: CachedForm[];
}

export type AppState = {
    // Cached data collated Org->Forms[]
    collatedData: OrgRelation[];
    // Page Title
    title: string;
    currentForm?: CachedForm;
    cacheForm?: boolean;
    showCacheForm?: boolean;
};

export enum AppActionTypes {
    SetData = "SetData",
    SetTitle = "SetTitle",
    SetForm = "SetForm",
    CacheForm = "CacheForm",
    ShowCacheForm = "ShowCacheForm",
}

export type AppActions =
    | {
          type: AppActionTypes.SetData;
          data: OrgRelation[];
      }
    | {
          type: AppActionTypes.SetTitle;
          title: string;
      }
    | {
          type: AppActionTypes.SetForm;
          currentForm?: CachedForm;
      }
    | {
          type: AppActionTypes.CacheForm;
          cacheForm: boolean;
      };

/**
 * Gets the organization from already collated data, or from the cache, or from a live source.
 * @param orgId The Organization Id to find.
 * @param data Previously collated data.
 * @returns An OrgRelation with the specified organization, and cached forms if applicable.
 */
export const getOrg = async (orgId: string): Promise<OrgRelation> => {
    const emptyOrg: OrgRelation = {
        org: { id: "unknown", boardsAccess: false, clientShortName: "unknown", formsAccess: false, name: "unknown" },
        forms: [],
    };
    if (!uuidValidate(orgId)) {
        return emptyOrg;
    }

    // Get cached org
    const orgSvc = new OrganizationDataService();
    const org = await orgSvc.getOrFetchById(orgId);
    if (org) {
        return { org: org, forms: [] };
    } else {
        return emptyOrg;
    }
};

export const appReducer = (state: AppState, action: AppActions): AppState => {
    switch (action.type) {
        case AppActionTypes.SetData: {
            return {
                ...state,
                collatedData: action.data,
            };
        }
        case AppActionTypes.SetTitle: {
            return {
                ...state,
                title: action.title,
            };
        }
        case AppActionTypes.SetForm: {
            return {
                ...state,
                currentForm: action.currentForm,
            };
        }
        case AppActionTypes.CacheForm: {
            return {
                ...state,
                cacheForm: action.cacheForm,
            };
        }
        default: {
            return state;
        }
    }
};

export const formCached = async (formId: string) => {
    if (formId) {
        const form = await new FormDataService().getLocalForm(formId);

        return form !== null;
    } else {
        return false;
    }
};

const AppContext = React.createContext({} as { state: AppState; dispatch: React.Dispatch<AppActions> });

export default AppContext;
