import { Typography, Box } from "@mui/material";
import React from "react";
import { makeStyles } from "@mui/styles";
import { ColorPaper } from "../shared/ColorPaper";
// Todo: Talk to Paulius
// import { ColorPaper } from "@juvare/common-web-ui/ColorPaper";
import { JuvareLogoFull } from "../shared/JuvareLogoFull";

interface HeadSectionProps {
    title?: string;
    subTitle?: string;
}

const useStyles = makeStyles(() => ({
    juvareLogo: {
        display: "flex",
        alignItems: "flex-start",
        height: 30,
        justifyContent: "flex-end",
    },
    description: {
        fontSize: "16px",
        fontWeight: 400,
        "& .MuiTypography-root": {
            fontSize: "16px",
        },
    },
    cacheDesc: {
        fontSize: "14px",
        fontWeight: "700",
    },
}));

const HeadSection: React.FC<HeadSectionProps> = (props: HeadSectionProps) => {
    const classes = useStyles();

    return (
        <ColorPaper gradient="black">
            <Box className={classes.juvareLogo}>
                <JuvareLogoFull />
            </Box>
            <Box>
                <Typography
                    sx={{ mt: 4, mb: 2, ml: 4, mr: 4 }}
                    variant="h6"
                    component="div"
                    className={classes.description}
                >
                    {props.title
                        ? props.title
                        : "Juvare forms can now be cached in your web browser so that they can be accessed even when you don't have internet access."}
                </Typography>
                <Typography
                    sx={{ mt: 4, mb: 2, ml: 4, mr: 4 }}
                    variant="h6"
                    component="div"
                    className={classes.cacheDesc}
                >
                    {props.subTitle
                        ? props.subTitle
                        : "Keep in mind, clearing your browser's cache will remove all cached forms from this page."}
                </Typography>
            </Box>
        </ColorPaper>
    );
};

export default HeadSection;
