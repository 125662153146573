import { Alert, Checkbox, FormControlLabel, FormGroup, Grid, Snackbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState, useCallback, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { FormDataService, CachedForm } from "../../Services/FormDataService";
import AppContext, { AppActionTypes, OrgRelation, getOrg } from "../App/AppContext";
import { ButtonFilled } from "../shared/Buttons";
import { createJuvareTheme } from "../shared/JuvareTheme";
// import { createJuvareTheme } from "@juvare/common-web-ui/JuvareTheme";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CachedIcon from "@mui/icons-material/Cached";
import PullToRefresh from "react-simple-pull-to-refresh";
import { ServiceWorkerHelper } from "../../ServiceWorkerHelper";

const theme = createJuvareTheme();

const useStyles = makeStyles(() => ({
    contentGrid: {
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    returnText: {
        color: theme.palette.info.main,
        fontSize: 14,
        lineHeight: "24px",
        fontWeight: 400,
    },
    returnLink: {
        fontSize: 16,
    },
    checkbox: {
        fontSize: 14,
        lineHeight: "24px",
        fontWeight: 400,
        color: theme.palette.info.main,
        "& .MuiCheckbox-root": {
            color: theme.palette.info.main,
        },
    },
    title: {
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: 600,
        paddingBottom: "15px",
        paddingTop: "10px",
    },
    desc: {
        fontSize: 14,
        lineHeight: "14px",
        fontWeight: 400,
        paddingBottom: "15px",
    },
    detailButtonGrid: {
        justifyContent: "center",
        display: "flex",
    },
    detailButton: {
        width: 171,
        fontSize: 13,
        lineHeight: "22px",
        fontWeight: 500,
        color: "white",
        height: 40,
        justifyContent: "space-between",
    },
    detailButtonIcon: {
        marginLeft: "10px",
    },
    formCard: {
        borderRadius: "4px",
        backgroundColor: "white",
        marginBottom: "15px",
        paddingLeft: "5px",
        paddingTop: "15px",
    },
    formCheckbox: {
        borderRadius: "2px",
        color: theme.palette.info.main,
        "& .MuiCheckbox-root": {
            color: theme.palette.info.main,
        },
        "& MuiCheckbox-root.Mui-checked": {
            color: theme.palette.info.main,
        },
    },
    formTitle: {
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: 600,
    },
    formDesc: {
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: 400,
        paddingBottom: "15px",
        paddingTop: "10px",
    },
}));

export default function OrgPage() {
    const classes = useStyles();
    const { id: orgId }: { id?: string } = useParams(); // rename id to orgId for clarity, too many ids floating here.
    const { state, dispatch } = useContext(AppContext);

    const formSvc = useMemo<FormDataService>(() => new FormDataService(), []);
    const [allChecked, setAllChecked] = useState<boolean>(false);
    const [sortedData, setSortedData] = useState<OrgRelation | undefined>(undefined);
    const [formsCached, setFormsCached] = useState<boolean>(false);

    const collateData = useCallback(
        async (org: OrgRelation, cached: CachedForm[], live: CachedForm[]) => {
            // Update matches
            cached.forEach((c) => {
                const form = live.find((f) => f.id === c.id);
                if (form) {
                    form.cached = true;
                    if (form.versionId !== c.versionId) {
                        formSvc.saveForm(form);
                    }
                    org.forms.push(form);
                    live.splice(live.indexOf(form), 1);
                } else {
                    // Check for the live form separately, if its been unpublished, then remove the form lcoally
                    // this is a little weak, maybe could use the online flag to check instead of whether there are other forms published
                    if (live.length > 0) {
                        // Form may not show because its Discoverable setting was removed, so let the form remain cached
                        //delete cached form since its no longer published...most likely
                        // formSvc.removeForm(c);
                    } else {
                        org.forms.push(c);
                    }
                }
            });

            // Push the rest
            org.forms.push(...live);
        },
        [formSvc],
    );

    const refreshLiveData = useCallback(async () => {
        if (orgId) {
            const org = await getOrg(orgId);

            if (org) {
                const cached = await formSvc.storedForms(orgId);
                const live = await formSvc.searchForms("", orgId);
                collateData(org, cached, live);

                dispatch({ data: [org], type: AppActionTypes.SetData });
            }
        }
    }, [collateData, dispatch, formSvc, orgId]);

    useEffect(() => {
        ServiceWorkerHelper.getInstance().updateOutdatedForms();
    }, []);

    useEffect(() => {
        (async () => {
            await refreshLiveData();
        })();
    }, [refreshLiveData]);

    useEffect(() => {
        setAllChecked(sortedData?.forms.every((e) => e.cached === true) ?? false);
    }, [sortedData]);

    useEffect(() => {
        const org = state.collatedData.find((f) => f.org.id === orgId);
        if (org) {
            const sorted: OrgRelation = { org: org.org, forms: [] };
            sorted.forms = org.forms.sort((a, b) => {
                if (a.name === b.name) {
                    return 0;
                } else if (a.name > b.name) {
                    return 1;
                } else {
                    return -1;
                }
            });
            setSortedData(sorted);
            dispatch({ type: AppActionTypes.SetTitle, title: sorted.org.name + " Forms" });
        } else {
            setSortedData(org);
            dispatch({ type: AppActionTypes.SetTitle, title: "Juvare Forms" });
        }
    }, [state.collatedData, orgId]);

    const toggleAllChecked = () => {
        if (sortedData) {
            if (allChecked) {
                sortedData?.forms.forEach((f) => {
                    f.cached = false;
                });
                setSortedData({ ...sortedData });
            } else {
                sortedData?.forms.forEach((f) => {
                    f.cached = true;
                });
                setSortedData({ ...sortedData });
            }
        }
    };

    const cacheSelectedForms = async () => {
        if (sortedData && sortedData.forms.length > 0) {
            sortedData.forms.forEach((f) => {
                if (f.cached) {
                    formSvc.saveForm(f);
                } else {
                    formSvc.removeForm(f);
                }
            });
        }

        setFormsCached(true);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setFormsCached(false);
    };

    return (
        <>
            <Snackbar
                open={formsCached === true}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
                    Your forms have been cached for offline use. When you visit this page again, they will automatically
                    update with newer versions, if available.
                </Alert>
            </Snackbar>
            <PullToRefresh onRefresh={refreshLiveData}>
                <Grid container direction="column" className={classes.contentGrid}>
                    <Grid item>
                        <Typography className={classes.returnText}>
                            <Link to={`/`} data-testid="return-link">
                                <ArrowBackIcon className={classes.returnLink} />
                                Return to cached forms
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.title}>{sortedData?.org.name}</Typography>
                        {sortedData && sortedData.forms && sortedData.forms.length > 0 && (
                            <Typography className={classes.desc}>Select forms to add to your cache</Typography>
                        )}
                        {!sortedData ||
                            !sortedData.forms ||
                            (sortedData.forms.length === 0 && (
                                <Typography className={classes.desc}>This organization has no forms</Typography>
                            ))}
                    </Grid>
                    <Grid item>
                        {sortedData && sortedData.forms && sortedData.forms.length > 0 && (
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={allChecked} />}
                                    label={`Select all ${sortedData.forms.length}`}
                                    className={classes.checkbox}
                                    onChange={toggleAllChecked}
                                />
                            </FormGroup>
                        )}
                    </Grid>
                    {sortedData &&
                        sortedData.forms.length > 0 &&
                        sortedData.forms.map((form) => (
                            <Grid item container key={form.id} className={classes.formCard} direction="row">
                                <Grid item xs={2}>
                                    <Checkbox
                                        checked={form.cached}
                                        onChange={() => {
                                            form.cached = !form.cached;
                                            setSortedData({ ...sortedData });
                                        }}
                                        className={classes.formCheckbox}
                                    ></Checkbox>
                                </Grid>
                                <Grid item container direction="column" xs={10}>
                                    <Typography className={classes.formTitle}>{form.name}</Typography>
                                    <Typography className={classes.formDesc}>{form.description}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    <Grid item className={classes.detailButtonGrid}>
                        <ButtonFilled className={classes.detailButton} onClick={cacheSelectedForms}>
                            <CachedIcon className={classes.detailButtonIcon} />
                            Cache Forms
                        </ButtonFilled>
                    </Grid>
                </Grid>
            </PullToRefresh>
        </>
    );
}
