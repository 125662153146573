interface Subscribable<TFunc extends Function> {
    add(handler: TFunc): Multicast<TFunc>;
    remove(handler: TFunc): Multicast<TFunc>;
}

export type Multicast<TFunc extends Function> = Subscribable<TFunc> & TFunc;

export default function multicast<TFunc extends Function>(...handlers: TFunc[]): Multicast<TFunc> {
    handlers = handlers;

    const subscribable: Subscribable<TFunc> = {
        add(handler) {
            return multicast(...handlers.concat(handler));
        },
        remove(handler) {
            return multicast(...handlers.filter((h) => h !== handler));
        },
    };

    const invoke: TFunc = ((...args: any[]) => {
        let result: any;
        handlers.forEach((handler) => (result = handler(...args)));
        return result;
    }) as any;

    return merge(invoke, subscribable);
}

export function merge<T1, T2>(onto: T1, from: T2): T1 & T2 {
    if (typeof from !== "object" || from instanceof Array || from === null) {
        throw new Error("merge: 'from' must be an ordinary object");
    } else {
        Object.keys(from).forEach((key) => ((onto as any)[key] = (from as any)[key]));
    }
    return onto as T1 & T2;
}
