import { IDBPDatabase } from "idb";
import { DataStores } from "../Data/OrganizationListModel";
import { ServiceBase } from "./ServiceBase";
import { DBSingleton } from "./FormDataService";

export interface IOrganization {
    id?: string;
    name: string;
    boardsAccess: boolean;
    formsAccess: boolean;
    clientShortName: string;
    customDomain?: string;
}

export interface OrganizationResponse extends IOrganization {
    id: string;
}

export class OrganizationDataService extends ServiceBase<OrganizationResponse> {
    private static db: IDBPDatabase<unknown> | undefined = undefined;

    public constructor(url?: string) {
        if (!url) {
            url = process.env.REACT_APP_API_BASE_URL + "/organization";
        }
        super(url);
    }

    Db = DBSingleton.Db;

    closeDb = DBSingleton.closeDb;

    getOrgById = async (orgId: string): Promise<OrganizationResponse | undefined> => {
        try {
            const resp = await this.get(`${orgId}`);
            return resp.data;
        } catch {
            return undefined;
        }
    };

    searchOrganization = async (orgName: string): Promise<OrganizationResponse[]> => {
        const resp = await this.getAll(`orgsearch?orgname=${orgName}`);
        return resp.data;
    };

    /**
     * @deprecated Orgs are no longer searchable so will be removing this
     */
    getOrFetch = async (orgName: string): Promise<OrganizationResponse[]> => {
        if (orgName) {
            try {
                const db = await this.Db();

                if (db) {
                    const obj = await db.get(DataStores.Organizations, orgName);
                    if (obj) {
                        return obj;
                    }

                    try {
                        const orgs = await this.searchOrganization(orgName);
                        if (orgs) {
                            orgs.map(async (org) => {
                                db.put(DataStores.Organizations, org);
                            });
                            return orgs;
                        }
                    } catch (e) {
                        console.log("No organizations found: " + e);
                    }
                }
            } catch (ex) {
                console.error(ex);
            }
        }
        return [];
    };

    getOrFetchById = async (orgId: string): Promise<OrganizationResponse | undefined> => {
        if (orgId) {
            try {
                const db = await this.Db();
                if (db) {
                    const obj = await db.get(DataStores.Organizations, orgId);
                    if (obj) {
                        return obj;
                    } else {
                        try {
                            const org = (await this.get(orgId)).data;
                            if (org) {
                                db.put(DataStores.Organizations, org);
                                return org;
                            }
                        } catch (e) {
                            console.log("No organizations found: " + e);
                        }
                    }
                }
            } catch (ex) {
                console.error(ex);
            }
        }
        return undefined;
    };

    saveOrg = async (org: OrganizationResponse): Promise<boolean> => {
        if (org) {
            const db = await this.Db();
            if (db) {
                // update db with new org
                db.put(DataStores.Organizations, org);
                return true;
            }
        }
        return false;
    };

    removeOrg = async (org: OrganizationResponse): Promise<boolean> => {
        if (org) {
            const db = await this.Db();
            if (db) {
                db.delete(DataStores.Organizations, org.id);
                return true;
            }
        }
        return false;
    };

    storedOrgs = async (): Promise<OrganizationResponse[]> => {
        const db = await this.Db();
        if (db) {
            const list = await db.getAll(DataStores.Organizations);
            if (list) {
                return list;
            }
        }

        return [];
    };

    getStoredOrg = async (id: string): Promise<OrganizationResponse | undefined> => {
        if (id) {
            try {
                const db = await this.Db();
                if (db) {
                    const org = await db.get(DataStores.Organizations, id);
                    if (org) {
                        return org;
                    }
                }
            } catch (ex) {
                console.error(ex);
            }
        }
        return undefined;
    };
}
