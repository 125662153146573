import { Grid, Typography, Tooltip, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import HeadSection from "../HeadSection";
import { FormDataService, FormSubmissionData, SubmissionStatus } from "../../Services/FormDataService";
import AccessAlarm from "@mui/icons-material/AccessAlarm";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorIcon from "@mui/icons-material/Error";
import EditIcon from "@mui/icons-material/Edit";
import { createSearchParams, useNavigate } from "react-router-dom";
import commChannel, { commMessages } from "../../commChannel";
import { ServiceWorkerHelper } from "../../ServiceWorkerHelper";
import { BroadcastChannel as bc } from "broadcast-channel";
import { createJuvareTheme } from "../shared/JuvareTheme";
import PullToRefresh from "react-simple-pull-to-refresh";
import AppContext, { AppActionTypes } from "../App/AppContext";

const theme = createJuvareTheme();

const useStyles = makeStyles(() => ({
    contentGrid: {
        paddingLeft: "16px",
        paddingRight: "16px",
    },
    title: { fontSize: "16px", fontWeight: 600, paddingBottom: "5px" },
    subTitle: { fontSize: "14px", fontWeight: 500, paddingBottom: "5px" },
    columnHeader: {
        paddingLeft: "5px",
        paddingTop: "5px",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: 600,
    },
    columnData: {
        paddingLeft: "5px",
        paddingTop: "5px",
        alignItems: "center",
        fontSize: "12px",
    },
    formCardHeader: {
        borderRadius: "4px",
        backgroundColor: "white",
        marginBottom: "5px",
        paddingLeft: "5px",
        paddingTop: "5px",
        alignItems: "center",
    },
    formCard: {
        borderRadius: "4px",
        backgroundColor: "white",
        marginBottom: "5px",
        paddingLeft: "5px",
        paddingTop: "5px",
        alignItems: "center",
    },
    formName: {
        fontSize: 16,
        lineHeight: "20px",
        fontWeight: 500,
    },
    formSubmitDate: {
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: 400,
        paddingBottom: "15px",
        paddingTop: "10px",
    },
    status: {
        marginTop: "-5px",
    },
    statusError: {
        color: theme.palette.error.main,
    },
    statusPending: {
        color: theme.palette.secondary.light,
    },
    statusSent: {
        color: theme.palette.success.main,
    },
    delete: {
        color: theme.palette.error.main,
        pointer: "cursor",
    },
    edit: {
        color: theme.palette.info.main,
    },
}));

const frmSvc = new FormDataService();

export default function Submissions() {
    const classes = useStyles();
    const [subs, setSubs] = useState<FormSubmissionData[]>([]);
    const [remoteSubs, setRemoteSubs] = useState<FormSubmissionData[]>([]);
    const broadcast = useRef<bc>();
    const helper = ServiceWorkerHelper.getInstance().init();
    const { state, dispatch } = useContext(AppContext);
    const navigate = useNavigate();

    const setStatus = async (sub: FormSubmissionData, status: SubmissionStatus) => {
        sub.statusDate = new Date();
        sub.status = status;
        await frmSvc.saveFormSubmission(sub);
    };

    const fetchData = useCallback(async () => {
        const data = await frmSvc.storedFormSubmissions();
        setSubs(data);

        const remote = await frmSvc.getFormSubmissionsByUser();
        if (remote && remote.count > 0) {
            setRemoteSubs(remote.items);
        }
    }, [setSubs]);

    const handleDelete = async (sub: FormSubmissionData) => {
        if (await frmSvc.removeFormSubmission(sub)) {
            subs.splice(subs.indexOf(sub), 1);
            setSubs([...subs]);
        }
    };

    const editSub = async (sub: FormSubmissionData) => {
        if (sub && sub.id) {
            const clickedForm = await frmSvc.getLocalFormSubmission(sub.id);

            if (clickedForm) {
                navigate({
                    pathname: `/forms/${sub.formId}`,
                    search: `?${createSearchParams({
                        submissionId: sub.data.submissionId,
                    })}`,
                });
            } else {
                console.error(`Form Submission could not be located. id: "${sub.id}"`);
            }
        }
    };

    const editRemoteSub = async (sub: FormSubmissionData) => {
        if (sub && sub.id) {
            navigate(
                {
                    pathname: `/forms/${sub.formId}`,
                    search: `?${createSearchParams({
                        submissionId: sub.id,
                    })}`,
                },
                { state: sub },
            );
        }
    };

    useEffect(() => {
        dispatch({ type: AppActionTypes.SetTitle, title: "Form Submissions" });
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        if (!broadcast.current) {
            broadcast.current = new bc(commChannel.Submissions);
        }
        const bcRef: bc = broadcast.current;

        return () => {
            bcRef.close();
            if (bcRef === broadcast.current) {
                broadcast.current = undefined;
            }
        };
    }, []);

    if (broadcast?.current) {
        const bcRef: bc = broadcast.current;
        bcRef.onmessage = (event) => {
            if (bcRef.isClosed) {
                return;
            }
            switch (event.type) {
                case commMessages.ProcessedRecord: {
                    const sub = subs.find((f) => f.id === event.subId);
                    if (sub) {
                        setStatus(sub, SubmissionStatus.sent).then(() => {
                            fetchData();
                        });
                    }
                    break;
                }
                case commMessages.Interrupted: {
                    const sub = subs.find((f) => f.id === event.subId);
                    if (sub) {
                        sub.statusDate = new Date();
                        sub.status = SubmissionStatus.error;
                        setSubs([...subs]);
                    }
                    break;
                }
                case commMessages.Completed:
                case commMessages.SubmissionAdded: {
                    fetchData();
                    break;
                }
                // TODO: Add other handlers for other messages
            }
        };
    }

    return (
        <PullToRefresh onRefresh={fetchData}>
            <Grid container direction={"column"}>
                <Grid item xs={12} md={6}>
                    <HeadSection
                        title="Form Submissions"
                        subTitle="Recent submissions can be edited if you are currently offline. Processed submissions can be edited and re-submitted by the original author, in some cases."
                    />
                </Grid>
                <Grid item xs={12} md={6} className={classes.contentGrid}>
                    <Typography sx={{ mt: 2, mb: 2 }} className={classes.title}>
                        Recent Submissions
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 2 }} className={classes.subTitle}>
                        You may edit these while offline. Clearing your cache and closing all browser tabs may remove
                        them, or you can do so manually.
                    </Typography>
                    <Grid container direction="column">
                        <Grid item container key="headers" className={classes.formCardHeader} direction="row">
                            <Grid item xs={2}>
                                <Typography className={classes.columnHeader}>Form Name</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.columnHeader}>Status</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.columnHeader}>Submitted Date</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.columnHeader}>Processed Date</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.columnHeader}>Actions</Typography>
                            </Grid>
                        </Grid>
                        {subs?.length > 0 &&
                            subs.map((sub) => {
                                return (
                                    <Grid
                                        item
                                        container
                                        key={sub.id}
                                        className={classes.formCardHeader}
                                        direction="row"
                                    >
                                        <Grid item xs={2}>
                                            <Typography className={classes.columnData}>{sub.formName}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.columnData}>{sub.status}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.columnData}>
                                                {new Date(sub.submitted).toString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.columnData}>
                                                {new Date(sub.processed).toString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction="row" xs={6} className={classes.status}>
                                            <Grid item>
                                                <IconButton onClick={fetchData}>
                                                    {sub.status === SubmissionStatus.error && (
                                                        <Tooltip title="An error has occurred. The submission will be retried">
                                                            <ErrorIcon className={classes.statusError} />
                                                        </Tooltip>
                                                    )}
                                                    {sub.status === SubmissionStatus.pending && (
                                                        <Tooltip title="The submission is pending">
                                                            <AccessAlarm className={classes.statusPending} />
                                                        </Tooltip>
                                                    )}
                                                    {sub.status === SubmissionStatus.sent && (
                                                        <Tooltip title="The submission has been sent successfully">
                                                            <CloudUploadIcon className={classes.statusSent} />
                                                        </Tooltip>
                                                    )}
                                                </IconButton>
                                                {sub.status === SubmissionStatus.pending && (
                                                    <>
                                                        <IconButton
                                                            className={classes.delete}
                                                            onClick={() => handleDelete(sub)}
                                                        >
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            className={classes.edit}
                                                            onClick={() => editSub(sub)}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} className={classes.contentGrid}>
                    <Typography sx={{ mt: 2, mb: 2 }} className={classes.title}>
                        Processed Submissions
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 2 }} className={classes.subTitle}>
                        If allowed, these can be edited and re-submitted by the original author.
                    </Typography>
                    <Grid container direction="column">
                        <Grid item container key="headers" className={classes.formCardHeader} direction="row">
                            <Grid item xs={2}>
                                <Typography className={classes.columnHeader}>Form Name</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.columnHeader}>Status</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.columnHeader}>Submitted Date</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.columnHeader}>Processed Date</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography className={classes.columnHeader}>Actions</Typography>
                            </Grid>
                        </Grid>
                        {remoteSubs?.length > 0 &&
                            remoteSubs.map((sub) => {
                                return (
                                    <Grid item container key={sub.id} className={classes.formCard} direction="row">
                                        <Grid item xs={2}>
                                            <Typography className={classes.columnData}>{sub.formName}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.columnData}>{sub.status}</Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.columnData}>
                                                {new Date(sub.submitted).toString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.columnData}>
                                                {new Date(sub.processed).toString()}
                                            </Typography>
                                        </Grid>
                                        <Grid item container direction="row" xs={4} className={classes.status}>
                                            <Grid item>
                                                <IconButton onClick={fetchData}>
                                                    {sub.status === "ERROR" && (
                                                        <Tooltip title="An error has occurred. The submission will be retried">
                                                            <ErrorIcon className={classes.statusError} />
                                                        </Tooltip>
                                                    )}
                                                    {sub.status === SubmissionStatus.pending && (
                                                        <Tooltip title="The submission is pending">
                                                            <AccessAlarm className={classes.statusPending} />
                                                        </Tooltip>
                                                    )}
                                                </IconButton>
                                                {sub.status === "PROCESSED" && (
                                                    <IconButton
                                                        className={classes.edit}
                                                        onClick={() => editRemoteSub(sub)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </Grid>
            </Grid>
        </PullToRefresh>
    );
}
