import { createTheme } from "@mui/material";
import { green, orange } from "@mui/material/colors";
import { Theme } from "@mui/material/styles";

export const juvareColors: Readonly<Record<"blue" | "red", Record<number | string, string>>> = {
    blue: {
        "200-10p": "rgba(140, 197, 247, 0.1)",
        "200-20p": "rgba(140, 197, 247, 0.2)",
        "200-50p": "rgba(140, 197, 247, 0.5)",
        "700-10p": "rgba(14, 109, 206, 0.1)",
        "700-20p": "rgba(14, 109, 206, 0.2)",
        "700-50p": "rgba(14, 109, 206, 0.5)",
        50: "#E2F1FD",
        200: "#8CC5F7",
        500: "#0F8EEF",
        700: "#0E6DCE",
        900: "#0757A8",
    },
    red: {
        "800-10p": "rgba(206, 14, 46, 0.1)",
        50: "#FFEBEF",
        200: "#F2989E",
        500: "#FC323D",
        800: "#CE0E2E",
        900: "#AA182C",
    },
};

export function createJuvareTheme(): Theme {
    return createTheme({
        components: {
            MuiLink: {
                defaultProps: {
                    underline: "hover",
                    color: juvareColors.blue[700], // default #185A7D
                },
            },
        },
        palette: {
            mode: "light",
            primary: {
                light: "#467B97", // default #42a5f5
                main: "#185A7D", // default #1976d2
                dark: "#103E57", // default #1565c0
            },
            secondary: {
                light: "#fc9a92", // default #ba68c8
                main: "#f44336", // default #9c27b0
                dark: "#cc2518", // default #7b1fa2
            },
            divider: "rgba(0, 0, 0, 0.12)", // default 0.12
            action: {
                active: "rgba(0, 0, 0, 0.24)", // default 0.54
                hover: "rgba(0, 0, 0, 0.04)", // default 0.04
                hoverOpacity: 0.04, // default 0.04
                selected: "rgba(0, 0, 0, 0.08)", // default 0.08
                selectedOpacity: 0.08, // default 0.08
                disabled: "rgba(0, 0, 0, 0.26)", // default 0.26
                disabledBackground: "rgba(0, 0, 0, 0.12)", // default 0.12
                disabledOpacity: 0.38, // default 0.38
                focus: "rgba(0, 0, 0, 0.12)", // default 0.12
                focusOpacity: 0.12, // default 0.12
                activatedOpacity: 0.12, // default 0.12
            },
            background: {
                default: "#fafafa", // default #fff
            },
            warning: {
                main: orange["500"],
            },
            success: {
                main: green["700"],
            },
            info: {
                main: juvareColors.blue[700],
            },
            error: {
                main: juvareColors.red[800],
            },
        },
        typography: {
            h1: {
                fontSize: 96,
                fontWeight: 300,
            },
            h2: {
                fontSize: 60,
                fontWeight: 300,
            },
            h3: {
                fontSize: 48,
                fontWeight: 400,
            },
            h4: {
                fontSize: 34,
                fontWeight: 400,
            },
            h5: {
                fontSize: 24,
                fontWeight: 400,
            },
            h6: {
                fontSize: 20,
                fontWeight: 500,
            },
            subtitle1: {
                fontSize: 16,
                fontWeight: 500,
            },
            subtitle2: {
                fontSize: 14,
                fontWeight: 500,
            },
            body1: {
                fontSize: 16,
                fontWeight: 400,
            },
            body2: {
                fontSize: 14,
                fontWeight: 400,
            },
            caption: {
                fontSize: 12,
                fontWeight: 400,
            },
            overline: {
                fontSize: 12,
                fontWeight: 400,
            },
        },
    });
}

export function createJuvareAppMenuTheme(): Theme {
    return createTheme({
        palette: {
            mode: "dark",
        },
    });
}
