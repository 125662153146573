import axios from "axios";
import { byPass, FileUpload } from "./Services/FormDataService";
import { SubmissionResponse } from "./SubmissionResponse";

const sendFileUpload = async (upload: FileUpload): Promise<SubmissionResponse> => {
    const resp: SubmissionResponse = {
        success: false,
    };

    if (upload && upload.file && upload.url) {
        try {
            const url = new URL(upload.url);
            url.searchParams.append(byPass, "true");
            const formData: FormData = new FormData();
            formData.append(upload.filename, upload.file);
            formData.append("name", upload.filename);

            await axios.post(url.toString(), formData);
            console.log("File Upload Submitted successfully");
            resp.success = true;
        } catch (err: any) {
            console.error(err);
            resp.message = err.toString();
        }

        return resp;
    } else {
        resp.message = "URL for file upload is not defined";
        console.error(resp.message);
        return resp;
    }
};

export default sendFileUpload;
