import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/App";
import "bootstrap/dist/css/bootstrap.css";
import "bootswatch/dist/flatly/bootstrap.min.css";
import "formiojs/dist/formio.full.min.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createJuvareTheme } from "./components/shared/JuvareTheme";
import { makeStyles } from "@mui/styles";
import { StyledEngineProvider, Theme } from "@mui/material/styles";

declare module "@mui/styles/defaultTheme" {
    type DefaultTheme = Theme;
}

const useStyles = makeStyles(() => ({
    section: {
        display: "flex",
        height: "100%",
        flexDirection: "column",
        backgroundColor: "#FAFAFA",
    },
}));

function Index() {
    const classes = useStyles();
    const theme = createJuvareTheme();

    return (
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                <ThemeProvider theme={theme}>
                    <section className={classes.section}>
                        {/* //TODO: Add ErrorBoundary/ErrorFallback later. Incorporate it to the juvare common-web-ui
                    <ErrorBoundary FallbackComponent={ErrorFallback}> */}
                        <App />
                        {/* </ErrorBoundary> */}
                    </section>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.StrictMode>
    );
}

ReactDOM.render(React.createElement(Index), document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
