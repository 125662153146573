/* eslint-disable no-fallthrough */
import { IDBPDatabase, TypedDOMStringList, openDB } from "idb";
import { DataStores, StoreIndices } from "./OrganizationListModel";
import { BroadcastChannel as bc } from "broadcast-channel";
import commChannel, { commMessages } from "../commChannel";
import { v4 as uuidv4 } from "uuid";

/** Current DB version required and used by this script */
export const DbVersion = 3;

const notifyAboutDbError = async (msg: string) => {
    const broadcast = new bc(commChannel.Submissions);
    broadcast
        .postMessage({
            type: commMessages.DbConnectionError,
            message: msg,
        })
        .finally(() => {
            if (!broadcast.isClosed) {
                broadcast.close();
            }
        });
};

export async function createDB(): Promise<IDBPDatabase<unknown>> {
    try {
        // Using https://github.com/jakearchibald/idb
        const db: IDBPDatabase<unknown> = await openDB("forms-mobile-test", DbVersion, {
            upgrade(db: IDBPDatabase<unknown>, oldVersion: number, newVersion: number, transaction) {
                // Attention: The new DB version is specified in the second parameter of the openDB() call above.

                // Switch over the oldVersion, *without breaks*, to allow the database to be incrementally upgraded.
                // Note: When database is initially created the oldVersion is 0.

                console.log(`DB migration from ver ${oldVersion} to ver ${newVersion} starting`);

                if (oldVersion < newVersion) {
                    // ===== Upgrading from older versions to the latest =====
                    // Starting upgrade from oldVersion to the newest one: 0 -> 1 -> 2 -> 3 ...

                    if (oldVersion < 1) {
                        // Upgrading from the initial DB version up to ver 1:
                        try {
                            // Create a store of objects for the initial version
                            db.createObjectStore(DataStores.Organizations, {
                                keyPath: "id",
                            });

                            const forms = db.createObjectStore(DataStores.Forms, {
                                keyPath: "id",
                            });

                            const formSubmissions = db.createObjectStore(DataStores.Submissions, {
                                keyPath: "id",
                            });

                            // Index for getting forms by orgId
                            forms.createIndex(StoreIndices.formByOrgId, "organization_id");
                            forms.createIndex(StoreIndices.formsByVersion, "versionId");

                            // Index for getting submissions by formid
                            formSubmissions.createIndex(StoreIndices.submissionsByFormId, "formId");
                        } catch (ex) {
                            // Normally an error here should never happend since we are starting from a fresh DB.
                            console.error(ex);
                        }
                    }

                    if (oldVersion < 2) {
                        // Upgrading from lower DB version up to ver 2

                        try {
                            // DB stuff added in Ver 2
                            const names: TypedDOMStringList<string> = db.objectStoreNames;
                            if (!names.contains(DataStores.FileUploads)) {
                                db.createObjectStore(DataStores.FileUploads, {
                                    keyPath: "filename",
                                });
                            }
                        } catch (ex) {
                            // This may happen if the DB object was created before without incrementing the DB version number.
                            console.error(ex);
                        }
                    }

                    if (oldVersion < 3) {
                        // IMPORTANT: It goes after upgrading from 1 to 2.
                        // Placeholder to add DB changes added in Ver 3

                        try {
                            // Adding settings store in ver 3
                            const names: TypedDOMStringList<string> = db.objectStoreNames;
                            if (!names.contains(DataStores.Settings)) {
                                db.createObjectStore(DataStores.Settings, {
                                    keyPath: "name",
                                });

                                // Add a default userId if not present
                                const newUserId = uuidv4();
                                db.add(DataStores.Settings, {
                                    name: "userId",
                                    value: newUserId,
                                });
                            }
                        } catch (ex) {
                            // This may happen if the DB object was created before without incrementing the DB version number.
                            console.error(ex);
                        }
                    }
                }
                /*
                else if (oldVersion > newVersion) {
                    // WARNING: This is just an idea of how to rollback to previous version if that could work.
                    // In reality the browser will not even open the database if the version number specified in the openDB() call above is less than the actual IndexedDB version in the browser's cache.
                    // So all this code is basically useless unless we figure out some workaround.

                    // ===== ROLLBACK: Downgrading from newer versions to the current =====
                    // Starting rollback from the latest version to the older one specified in newVersion: ... -> 3 -> 2 -> 1

                    //if (newVresion < 3) {
                    // rolling back from DB version 3 to 2. IMPORTANT: It goes before rolling back from 2 to 1.
                    //   Placeholder to remove DB changes that were added in ver 3
                    //}

                    if (newVersion < 2) {
                        try {
                            // rolling back from DB version 2 to 1
                            db.deleteObjectStore(DataStores.FileUploads);
                        } catch (ex) {
                            console.error(ex);
                        }
                    }
                }
                */

                console.log(`DB migration from ver ${oldVersion} to ver ${newVersion} is complete`);
            },
            blocking() {
                // Called if this connection is blocking a future version of the database from opening. This is similar to the versionchange event in plain IndexedDB
                console.error("DB Blocking");
                notifyAboutDbError("DB Blocking due to version change");
            },
            blocked() {
                // Called if there are older versions of the database open on the origin, so this version cannot open. This is similar to the blocked event in plain IndexedDB
                console.error("DB Blocked");
                notifyAboutDbError("DB Blocked. Close other tabs and reload");
            },
            terminated() {
                // Called if the browser abnormally terminates the connection, but not on regular closures like calling db.close(). This is similar to the close event in plain IndexedDB
                console.error("DB connection terminated");
                notifyAboutDbError("DB connection terminated");
            },
        });

        return db;
    } catch (ex) {
        console.error("Failed to open DB", ex);
        notifyAboutDbError("Failed to open database");
        throw new Error("Failed to open database");
    }
}
