import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export class ServiceBase<T> {
    public baseUrl: string | undefined;

    public constructor(url?: string) {
        this.baseUrl = url ?? process.env.REACT_APP_API_BASE_URL;
    }

    async get(path: string): Promise<AxiosResponse<T>> {
        return await axios.get<T>(`${this.baseUrl}/${path}`);
    }

    async getAll(path: string): Promise<AxiosResponse<T[]>> {
        return await axios.get<T[]>(`${this.baseUrl}/${path}`);
    }

    async put(path: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T, any>> {
        return await axios.put<T>(`${this.baseUrl}/${path}`, data, config);
    }

    async post(path: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await axios.post<T>(`${this.baseUrl}/${path}`, data, config);
    }

    async delete(path: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return await axios.delete(`${this.baseUrl}/${path}`, config);
    }
}
