/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Components } from "formiojs";
import { FormDataService } from "../../../../Services/FormDataService";
import { ServiceWorkerHelper } from "../../../../ServiceWorkerHelper";

const FileComponent = (Components as any).components.file;

export default class FileUploadComponent extends FileComponent {
    helper = ServiceWorkerHelper.getInstance().init();
    formSvc = new FormDataService();

    static schema(...extend: any[]) {
        return FileComponent.schema(
            {
                type: "file",
                label: "Upload",
                key: "file",
                webcam: true,
                image: true,
            },
            ...extend,
        );
    }

    static get builderInfo() {
        return {
            title: "file",
            group: "Advanced",
            icon: "file",
            weight: 90,
            schema: FileUploadComponent.schema(),
        };
    }

    get defaultSchema() {
        return FileUploadComponent.schema();
    }

    // These methods are other overrides that might be useful in the future.

    // browseFiles = () => {
    //     console.log("browseFiles called");
    //     return super.browseFiles();
    // };

    // get imageUpload() {
    //     console.log("imageUpload called");
    //     return super.imageUpload;
    // }

    // attach = (element: any) => {
    //     console.log("attach called");
    //     const val = super.attach();
    //     return val;
    // };

    // getFile = (fileInfo: any) => {
    //     console.log("getFile called");
    //     return super.getFile(fileInfo);
    // };

    loadImage = async (fileInfo: any) => {
        async function updateFile(fileInfo: any, svc: FormDataService): Promise<string> {
            try {
                // Get original with original name
                const file = await svc.getFileUpload(fileInfo.originalName);
                if (file) {
                    // Save original File with new name
                    await svc.saveFileUpload(fileInfo.name, file.file, fileInfo.url);
                    // Delete original
                    await svc.deleteFileUpload(fileInfo.originalName);
                }
            } catch (e) {
                console.log(e);
            } finally {
                return fileInfo.url;
            }
        }

        return updateFile(fileInfo, this.formSvc);
    };

    deleteFile = async (fileInfo: any) => {
        async function deleteLocalFile(fileInfo: any, svc: FormDataService): Promise<void> {
            try {
                await svc.deleteFileUpload(fileInfo.name);
            } catch (e) {
                console.log(e);
            } finally {
                return;
            }
        }

        await deleteLocalFile(fileInfo, this.formSvc);
        return super.deleteFile(fileInfo);
    };

    upload = async (files: any) => {
        async function saveLocalFile(files: FileList, svc: FormDataService): Promise<void> {
            try {
                const file: File = files[0];
                await svc.saveFileUpload(file.name, file);
            } catch (e) {
                console.log(e);
            } finally {
                return;
            }
        }

        await saveLocalFile(files, this.formSvc);
        return super.upload(files);
    };

    constructor(component: any, options: any, data: any) {
        if (component) {
            component.fileMaxSize = "100MB";
            component.customClass = "formio-component-file";
        }

        super(component, options, data);
    }
}
