import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Components, Form } from "@formio/react";
import { makeStyles } from "@mui/styles";
import { ButtonFilled } from "../shared/Buttons";
import { v4 } from "uuid";
import axios from "axios";
import components from "./Custom";
import {
    FormDataService,
    FormResponse,
    FormSubmissionData,
    formsUrlRoot,
    SubmissionStatus,
    urlNoCacheOpt,
} from "../../Services/FormDataService";
import { Alert, Box, Snackbar } from "@mui/material";
import commChannel, { commMessages, CommResponse } from "../../commChannel";
import { ServiceWorkerHelper } from "../../ServiceWorkerHelper";
import { BroadcastChannel as bc } from "broadcast-channel";
import AppContext, { AppActionTypes } from "../App/AppContext";
import "./Renderer.css";

Components.setComponents(components);

function parseSearchParameters() {
    const searchParams = new URLSearchParams(window.location.search);
    const parameters: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
        if (key !== "submissionId") {
            parameters[key] = value;
        }
    });
    return parameters;
}

const emptyForm: FormResponse = {
    name: "Form",
    id: "",
    organization_id: "",
    createdAt: "",
    createdBy: "",
    formState: "",
    versionId: "",
};

const useStyles = makeStyles(() => ({
    actionButton: {
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: 500,
        color: "white",
        height: 40,
        justifyContent: "space-between",
        margin: "0 5px",
    },
}));

const getBaseSubmitMessageData = () => {
    return {
        display: "form",
        components: [
            {
                html: '<h2>&nbsp;</h2><h2 style="text-align:center;">Your form has been submitted successfully.</h2>',
                type: "content",
            },
        ],
    };
};

const getSubmitMsg = (formData: FormResponse) => {
    if (formData) {
        const msg = ((formData as any).submitMsgJson ?? formData.submitMessageJson) || getBaseSubmitMessageData();
        if (typeof msg === "string") {
            try {
                return JSON.parse(msg);
            } catch (ex) {
                console.error(ex);
            }
        }

        return msg;
    }

    return undefined;
};

const JsonFileExt = ".json";
const formSvc = new FormDataService();

const Renderer: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const [dataFileName, setDataFileName] = useState(
        // dataFileName is formId + ".json".
        // Get formId or friendlyName from the URL pathname and append .json file ext to it.
        // Note: The pathname value can be one of: "/forms/{formId}", "/{formId}", "/{friendlyName`}"
        window.location.pathname.replace(/^\/forms\//, "").replace(/^\//, "") + JsonFileExt,
    );
    const [formId, setFormId] = useState(dataFileName.replace(/\.json$/, ""));
    const [submissionId, setSubmissionId] = useState("");
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState<React.ReactNode>();
    const [formData, setFormData] = React.useState<FormResponse>(emptyForm);
    const [formDefinition, setFormDefinition] = React.useState<any>();
    const [submission, setSubmission] = React.useState<any>({});
    const [response, setResponse] = React.useState<CommResponse>();
    const [isFormNewVerAvail, setIsFormNewVerAvail] = React.useState(false);
    const broadcast: React.MutableRefObject<bc | null> = useRef(null);
    const helper: ServiceWorkerHelper = useRef(ServiceWorkerHelper.getInstance().init()).current;
    const forceNoCache = useRef(false);
    const currReqId = useRef(0); // form retrieval request number
    const isSubmitInProgress = useRef(false);

    // For use later
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const submissionId = searchParams.get("submissionId");
        if (submissionId) {
            setSubmissionId(submissionId);
        } else {
            setSubmissionId(v4());
        }

        if (!broadcast.current) {
            broadcast.current = new bc(commChannel.Submissions);
        }

        return () => {
            if (broadcast.current && !broadcast.current.isClosed) {
                broadcast.current.close();
            }
            broadcast.current = null;
        };
    }, []);

    useEffect(() => {
        if (submissionId) {
            const searchParams = new URLSearchParams(window.location.search);
            const remote = searchParams.get("remote") === "true";
            const data = location.state as any;
            if (remote) {
                formSvc.getFormSubmissionsByUser(submissionId).then((data) => {
                    if (data && data.items?.length === 1) {
                        const item = data.items[0] as any;
                        setSubmission({ ...emptyForm, data: JSON.parse(item.data), dataId: item.id });
                    }
                });
            } else if (data) {
                setSubmission({ ...emptyForm, data: JSON.parse(data.data), dataId: data.dataId });
            } else {
                const fetchData = async () => {
                    const data = await formSvc.storedFormSubmissions();
                    return data;
                };

                fetchData().then((submissions) => {
                    const submission = submissions.find(
                        (sub) => sub.data.submissionId === submissionId && sub.status === SubmissionStatus.pending,
                    );

                    if (submission) {
                        setSubmission(submission);
                    }
                });
            }
        }
    }, [submissionId]);

    React.useEffect(() => {
        if (!broadcast.current) {
            return;
        }

        const bcRef: bc = broadcast.current;

        bcRef.onmessage = (msg) => {
            if (!msg) {
                return; // so you don't have to check if msg is not null all the time.
            }
            if (msg.type === commMessages.OfflineFormsUpdated) {
                if (
                    formData &&
                    Array.isArray(msg.formRefs) &&
                    msg.formRefs.find(
                        (f: { formId: string; versionId: string }) =>
                            f && f.formId === formData.id && f.versionId !== formData.versionId,
                    )
                ) {
                    setIsFormNewVerAvail(true);
                }
            } else if (msg.type === commMessages.AppVersionUpdated) {
                forceNoCache.current = true;
            } else if (submission?.id && msg.subId === submission.id) {
                switch (msg.type) {
                    case commMessages.Queued: {
                        setResponse(msg.subId);
                        break;
                    }
                    // TODO: Add other handlers for other messages
                }
            }
        };
    }, [formData, submission]);

    React.useEffect(() => {
        if (formData?.name) {
            document.title = formData.name;
        }
    }, [formData]);

    React.useEffect(() => {
        // Retrieve and cache form template.
        const getFriendlyPath = (friendly: string) => {
            if (friendly.startsWith("/")) {
                friendly = friendly.substring(1);
            }
            const url = process.env.REACT_APP_API_BASE_URL;
            axios
                .get(`${url}/formPath?friendly=${friendly}`)
                .then((res) => {
                    const actualFormId: string = res?.data?.valid && res.data.message;
                    if (!actualFormId) {
                        throw new Error("Could not retrieve actual formId");
                    }
                    setLoading(false);
                    setError(undefined);
                    setDataFileName(`${actualFormId}.json`);
                    setFormId(actualFormId);
                })
                .catch((loadError) => {
                    setLoading(false);
                    setError(<p>Form not found or could not be retrieved</p>);
                    setFormDefinition(undefined);
                    setFormData({ ...emptyForm, name: "Failed to load via friendly name" });
                    console.error("Failed loading '%s', error: '%s'", dataFileName, loadError.message);
                });
        };

        const checkAndUpdateLocalFormCache = async (liveForm: FormResponse) => {
            if (!liveForm?.id || !helper) {
                return;
            }

            try {
                const cachedForm: FormResponse | null = await formSvc.getLocalForm(liveForm.id);

                if (cachedForm && cachedForm.versionId !== liveForm.versionId) {
                    await helper.saveFormOfflineAndNotify(liveForm);
                }
            } catch (ex) {
                console.error(ex);
            }
        };

        let isDisposing = false;
        let reqTimer: ReturnType<typeof setTimeout> | undefined;
        currReqId.current = 0;

        const tryLoading = (reqId: number) => {
            // Note: the Form Url will pull cached version first if used without dynamic param "ts", which can be served from the Cache Storage, or from Browser's cache, or from some network cache.
            // To get most recent version from the server we add "?ts=" query param with a timestamp to the URL, but then it may be slower in offline mode.
            // Note2: if urlNoCacheOpt added (network prioritized over cache), then it is very slow if offline mode. But without it the cached version can be stale version.
            const origFormUrl =
                `${formsUrlRoot}/${dataFileName}?ts=${new Date().getTime()}` +
                (forceNoCache.current && helper.isOnline() ? `&${urlNoCacheOpt}` : "");

            // Note: if cache is not disabled, then the axios.get() call below can remain blocked in case of service worker version update. Blocked in FormJsonStrategy._handle() call.
            // We add a timeout to that to notify the user instead of making it look frozen.
            axios
                .get(origFormUrl)
                .then((res) => {
                    if (reqTimer !== undefined) {
                        clearTimeout(reqTimer);
                        reqTimer = undefined;
                    }

                    if (currReqId.current !== reqId) {
                        // this means the request was blocked and we sent another request. We dispose the first one.
                        console.log("Disposing of timed out request result.");
                        return;
                    }

                    if (!isDisposing) {
                        // check if the response is not the default index.html page (if form not found), but the form json.
                        if (!res?.data?.json) {
                            throw new Error("Invalid form data received");
                        }
                        if (!res.data.id) {
                            res.data.id = formId;
                        }

                        forceNoCache.current = false;
                        setLoading(false);
                        setError(undefined);
                        setFormData(res.data);
                        setFormDefinition(res.data.json);

                        res.data.origFormUrl = origFormUrl;
                        checkAndUpdateLocalFormCache(res.data); // Note: res.data may be a previously cached form, so version change may not be detected here.

                        dispatch({ type: AppActionTypes.SetTitle, title: res.data.name });
                        dispatch({ type: AppActionTypes.SetForm, currentForm: res.data });
                    }
                })
                .catch((_loadError) => {
                    if (reqTimer !== undefined) {
                        clearTimeout(reqTimer);
                        reqTimer = undefined;
                    }

                    if (!isDisposing) {
                        console.error("Failed to load form from network:", dataFileName, _loadError);
                        const formIdOrName = dataFileName.replace(JsonFileExt, "");
                        // Try local db
                        formSvc
                            .getLocalForm(formIdOrName) // will return null if form not found in the local DB
                            .then((form) => {
                                if (!isDisposing) {
                                    if (form) {
                                        if (!form?.json) {
                                            throw new Error("Could not get form json from the local DB");
                                        }
                                        if (!form.id) {
                                            form.id = formIdOrName;
                                        }

                                        setLoading(false);
                                        setError(undefined);
                                        setFormData(form);
                                        setFormDefinition(
                                            typeof form.json === "string" ? JSON.parse(form.json) : form.json,
                                        );

                                        dispatch({ type: AppActionTypes.SetTitle, title: form.name });
                                        dispatch({ type: AppActionTypes.SetForm, currentForm: form });
                                    } else {
                                        if (!isDisposing) {
                                            getFriendlyPath(formIdOrName);
                                        }
                                    }
                                }
                            })
                            .catch((localErr) => {
                                if (!isDisposing) {
                                    console.error("Failed to lookup form in DB", localErr);
                                    getFriendlyPath(formIdOrName);
                                }
                            });
                    }
                });
        };

        // Note: if there was a service worker upgrade and more than one tab open, the DB may be locked and axios.get() will block until all tabs sharing same service worker are reloaded or closed.
        // We add a timeout to that to notify the user instead of making it look frozen.
        reqTimer = setTimeout(() => {
            reqTimer = undefined;
            setLoading(false);
            const errNode: React.ReactNode = (
                <p>
                    Could not load form. Please close other tabs where forms page is still open and click Retry button
                    below.
                    <br />
                    <br />
                    <button
                        onClick={() => {
                            forceNoCache.current = true;
                            currReqId.current++;
                            tryLoading(currReqId.current);
                        }}
                    >
                        Retry
                    </button>
                </p>
            );

            setError(errNode);
        }, 15000); // wait up to 15 seconds, in case of a slow connection.

        tryLoading(currReqId.current);

        return () => {
            isDisposing = true;
            if (reqTimer !== undefined) {
                clearTimeout(reqTimer);
                reqTimer = undefined;
            }
            dispatch({ type: AppActionTypes.SetForm, currentForm: undefined });
        };
    }, [dataFileName, helper]);

    const handleSubmit = async (event: any) => {
        if (isSubmitInProgress.current) {
            // to prevent double-click submissions
            return;
        }

        const submissionData: FormSubmissionData = {
            parameters: parseSearchParameters(),
            data: {
                ...event.data,
                submissionId: submissionId,
                formId: formId,
            },
            formId: formId,
            id: submissionId,
            submitted: new Date().toISOString(),
            versionId: formData.versionId,
            formName: formData.name,
            status: SubmissionStatus.pending,
            processed: new Date().toISOString(),
            userId: await formSvc.getUserId(),
        };

        setSubmission(submissionData);

        let reqTimer: ReturnType<typeof setTimeout> | undefined;

        isSubmitInProgress.current = true;

        const trySaving = async () => {
            try {
                // Note: if there was a service worker upgrade and more than one tab open, the DB may be locked and formSvc.saveFormSubmission() will block until all tabs sharing same service worker are reloaded or closed.
                // We add a timeout to that to notify the user instead of making it look frozen.
                const isSaved = await formSvc.saveFormSubmission(submissionData);

                // No exception thrown so far

                if (isSaved) {
                    if (broadcast.current) {
                        broadcast.current.postMessage({
                            subId: submissionId,
                            type: commMessages.SubmissionAdded,
                        });
                    }

                    setError(undefined);
                    setIsFormNewVerAvail(false); // hide the "New version available" message if any is shown.
                    setFormDefinition(getSubmitMsg(formData));
                }
            } catch (ex) {
                console.error("Failed to save form submission", ex);
            } finally {
                isSubmitInProgress.current = false;
                if (reqTimer !== undefined) {
                    clearTimeout(reqTimer);
                    reqTimer = undefined;
                }
            }
        };

        // Note: if there was a service worker upgrade and more than one tab open, the DB may be locked and formSvc.saveFormSubmission() will block until all tabs sharing same service worker are reloaded or closed.
        // We add a timeout to that to notify the user instead of making it look frozen.
        reqTimer = setTimeout(() => {
            reqTimer = undefined;
            const errNode: React.ReactNode = (
                <p>
                    Could not submit form data.
                    <br />
                    Please keep this page open and close other tabs where forms page is still open.
                </p>
            );

            setError(errNode);
        }, 3000); // give it 3 seconds to save the submission data in the DB

        trySaving();
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setResponse(undefined);
    };

    const handleCloseNewVerAvail = (ev?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason !== "clickaway") {
            setIsFormNewVerAvail(false);
        }
    };

    const loadNewVersion = async () => {
        setIsFormNewVerAvail(false);

        const newFormDef: FormResponse | null = await formSvc.getLocalForm(formId);

        if (newFormDef?.json) {
            setLoading(true); // it must recreate the <Form > element, otherwise submit does not work with newer form version.
            setFormDefinition(newFormDef.json);
            setLoading(false);
        }
    };

    if (loading) {
        return <div style={{ padding: "20px" }}>Loading form...</div>;
    } else if (error) {
        return <div style={{ padding: "20px" }}>{error}</div>;
    } else {
        return (
            <Box paddingLeft="16px" paddingRight="16px">
                <Snackbar
                    open={response !== undefined}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert onClose={handleClose} severity="warning" sx={{ width: "100%" }}>
                        You are currently offline, your form will automatically submit when you connect to the internet
                    </Alert>
                </Snackbar>
                {isFormNewVerAvail && (
                    <Snackbar
                        open={isFormNewVerAvail}
                        onClose={handleCloseNewVerAvail}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <Alert
                            onClose={handleCloseNewVerAvail}
                            severity="warning"
                            sx={{ width: "100%" }}
                            closeText="Dismiss"
                            action={
                                <>
                                    <ButtonFilled
                                        size="small"
                                        className={classes.actionButton}
                                        onClick={loadNewVersion}
                                    >
                                        Load new
                                    </ButtonFilled>
                                    <ButtonFilled
                                        size="small"
                                        className={classes.actionButton}
                                        onClick={handleCloseNewVerAvail}
                                    >
                                        Ignore
                                    </ButtonFilled>
                                </>
                            }
                        >
                            There is a new version of this form available. Do you want to reload with the new version?
                        </Alert>
                    </Snackbar>
                )}
                <Form
                    form={formDefinition}
                    submission={submission}
                    onSubmit={handleSubmit}
                    options={{ noAlerts: true }}
                />
            </Box>
        );
    }
};

export default Renderer;
