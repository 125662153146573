import { createDB } from "../Data/createDb";
import { IDBPDatabase } from "idb";
import { DataStores, StoreIndices } from "../Data/OrganizationListModel";
import { ServiceBase } from "./ServiceBase";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

export const pwaFormsCacheId = "pwa-forms";
export const formsUrlRoot = "/forms";
export const urlNoCacheOpt = "nocache";
export const byPass = "bypass";

export interface Form {
    id?: string;
    name: string;
    description?: string;
    json?: string;
    submitMessageJson?: string;
    version?: string;
    formState?: string;
    webeocInstanceId?: string;
    webeocInstanceIncidentName?: string;
    webeocInstanceBoardName?: string;
    webeocInstanceInputViewName?: string;
    enableUpdate?: boolean;
    friendlyName?: string;
}

export interface FormResponse extends Form {
    id: string;
    organization_id: string;
    versionId: string;
    formState: string;
    createdBy: string;
    createdAt: string;
    updatedBy?: string;
    updatedAt?: string;
    origFormUrl?: string;
}

export interface CachedForm extends FormResponse {
    cached: boolean;
}

export interface FormRef {
    formId: string;
    versionId: string;
    origFormUrl?: string; // The original unique form URL that retrieved the form.
}
export interface OfflineFormUpdateMessage {
    type: string; // commMessages.OfflineFormsUpdated;
    formRefs: FormRef[];
}

export interface OfflineFormDeleteMessage {
    type: string; // commMessages.OfflineFormsDeleted;
    formRefs: FormRef[];
}

export interface FormValidationResponse {
    valid: boolean;
    message: string;
}

export enum SubmissionStatus {
    pending,
    sent,
    error,
}

export interface FormSubmissionData {
    parameters: {
        [key: string]: string;
    };
    data: {
        submissionId: string;
        formId: string;
        [key: string]: string;
    };
    formId: string;
    id: string;
    submitted: string;
    versionId: string;
    formName: string;
    status: SubmissionStatus | string;
    statusDate?: Date;
    userId?: string;
    processed: string;
}

export interface FormSubmissionResponse {
    count: number;
    items: FormSubmissionData[];
}

export interface FormSubmissionResponseItem {
    formId: string;
    formName: string;
    id: string;
    formVersion: string;
    webEocUrl: string;
    submitted: string;
    processed: string;
    status: string;
    errorMessage: string;
    dataId: number;
    data?: string;
}

export interface FileUpload {
    filename: string;
    file: File;
    url?: string;
}

export class DBSingleton {
    private static db: IDBPDatabase<unknown> | undefined = undefined;

    public static async Db(): Promise<IDBPDatabase> {
        if (!DBSingleton.db) {
            DBSingleton.db = await createDB();
        }
        return DBSingleton.db;
    }

    public static closeDb(): void {
        if (DBSingleton.db) {
            try {
                DBSingleton.db.close();
            } catch (ex) {
                console.error(ex);
            } finally {
                DBSingleton.db = undefined;
            }
        }
    }
}

export class FormDataService extends ServiceBase<FormResponse> {
    public constructor(url?: string) {
        super(url);
    }

    Db = DBSingleton.Db;

    closeDb = DBSingleton.closeDb;

    /** get form from the server, by form id */
    getForm = async (formId: string): Promise<FormResponse> => {
        const resp = await this.get("form/" + formId);
        return resp.data;
    };

    /** get all discoverable forms from the server */
    loadAllForms = async (): Promise<FormResponse[]> => {
        const resp = await this.getAll("form/");
        return resp.data;
    };

    retrySubmission = (submissionId: string) => {
        return this.put("retry-submission/" + submissionId);
    };

    /** retrieve form(s) from the server by optional formName and optional organization Id */
    searchForms = async (formName?: string, orgId?: string): Promise<CachedForm[]> => {
        const resp = await this.getAll(`form/search?formName=${formName}&orgId=${orgId}`);
        return resp.data.map((m: any) => {
            return { ...m, organization_id: m.orgId, cached: false };
        });
    };

    /** Save a form into the local DB */
    saveForm = async (form: FormResponse): Promise<boolean> => {
        // form id is a primary key in the Forms local DB, make sure the form's id and json data are provided.
        if (!form?.id || !form.json) {
            throw new Error("Invalid form data");
        }

        const db = await this.Db();
        if (db) {
            // update db with new org
            db.put(DataStores.Forms, form);
            return true;
        }
        return false;
    };

    /** Remove a form from the local DB */
    removeForm = async (form: FormResponse): Promise<boolean> => {
        return this.removeFormById(form?.id);
    };

    /** Remove a form from the local DB by formId */
    removeFormById = async (formId: string): Promise<boolean> => {
        if (formId) {
            const db = await this.Db();
            if (db) {
                db.delete(DataStores.Forms, formId);
                return true;
            }
        }
        return false;
    };

    /** Retrieve all forms from the local DB */
    storedForms = async (orgId?: string): Promise<CachedForm[]> => {
        const db = await this.Db();
        if (db) {
            let list = [];
            if (orgId) {
                list = await db.getAllFromIndex(DataStores.Forms, StoreIndices.formByOrgId, orgId);
            } else {
                list = await db.getAll(DataStores.Forms);
            }
            if (list) {
                return list;
            }
        }

        return [];
    };

    /** get form stored in the local DB, by form id */
    getLocalForm = async (id: string): Promise<CachedForm | null> => {
        if (id) {
            try {
                const db = await this.Db();
                if (db) {
                    const form = await db.get(DataStores.Forms, id);
                    if (form) {
                        return form;
                    }
                }
            } catch (ex) {
                console.error(ex);
            }
        }

        return null;
    };

    saveFormSubmission = async (data: FormSubmissionData): Promise<boolean> => {
        if (data) {
            try {
                const db = await this.Db();
                if (db) {
                    // update db with new org
                    db.put(DataStores.Submissions, data);
                    return true;
                }
            } catch (ex) {
                console.error("saveFormSubmission failed", ex);
            }
        }
        return false;
    };

    removeFormSubmission = async (data: FormSubmissionData): Promise<boolean> => {
        if (data) {
            const db = await this.Db();
            if (db) {
                try {
                    db.delete(DataStores.Submissions, data.id);
                    return true;
                } catch (e) {
                    return true;
                }
            }
        }
        return false;
    };

    storedFormSubmissions = async (formId?: string): Promise<FormSubmissionData[]> => {
        try {
            const db = await this.Db();
            if (db) {
                let list = [];
                if (formId) {
                    list = await db.getAllFromIndex(DataStores.Submissions, StoreIndices.submissionsByFormId, formId);
                } else {
                    list = await db.getAll(DataStores.Submissions);
                }
                if (list) {
                    return list;
                }
            }
        } catch (ex) {
            console.error(ex);
        }

        return [];
    };

    getLocalFormSubmission = async (id: string): Promise<FormSubmissionData | null> => {
        try {
            const db = await this.Db();
            if (db) {
                const sub = await db.get(DataStores.Submissions, id);
                if (sub) {
                    return sub;
                }
            }
        } catch {}

        return null;
    };

    saveFileUpload = async (filename: string, file: File, url?: string): Promise<boolean> => {
        try {
            const db = await this.Db();
            if (db) {
                db.put(DataStores.FileUploads, { filename: filename, file: file, url });
                return true;
            }

            return true;
        } catch (ex) {
            console.error(ex);
        }

        return false;
    };

    getFileUpload = async (filename: string): Promise<FileUpload | undefined> => {
        try {
            const db = await this.Db();
            if (db) {
                const file = await db.get(DataStores.FileUploads, filename);
                if (file) {
                    return file;
                }
            }
        } catch (ex) {
            console.error(ex);
        }

        return undefined;
    };

    getAllFileUploads = async (): Promise<FileUpload[] | undefined> => {
        try {
            const db = await this.Db();
            if (db) {
                const files = await db.getAll(DataStores.FileUploads);
                if (files) {
                    return files;
                }
            }
        } catch (ex) {
            console.error(ex);
        }

        return undefined;
    };

    deleteFileUpload = async (filename: string): Promise<boolean> => {
        try {
            const db = await this.Db();
            if (db) {
                await db.delete(DataStores.FileUploads, filename);
                return true;
            }
        } catch {
            console.log(`File: "${filename}" not found`);
        }

        return false;
    };

    getUserId = async (): Promise<string | undefined> => {
        const db = await this.Db();
        if (db) {
            const settings = await db.getAll(DataStores.Settings);
            if (settings && settings.length > 0) {
                const setting = settings.find((s) => s.name === "userId")?.value;
                if (setting) {
                    return setting;
                } else {
                    return await this.saveUserId();
                }
            } else {
                return await this.saveUserId();
            }
        }

        return undefined;
    };

    // Its possible the user can clear their own db, so this will set a new id.
    saveUserId = async (): Promise<string | undefined> => {
        const db = await this.Db();
        if (db) {
            const newUserId = uuidv4();
            await db.add(DataStores.Settings, {
                name: "userId",
                value: newUserId,
            });
            return newUserId;
        }

        return undefined;
    };

    // {{baseUrl}}/forms-api/v1/form/submissionsByUser?sort=ASC&size=-1&page=-1&userId=d3e0598f-7738-4af3-a602-d2b3f8d54de4
    getFormSubmissionsByUser = async (submissionId?: string): Promise<FormSubmissionResponse> => {
        const userId = await this.getUserId();
        let url = `${this.baseUrl}/form/submissionsByUser?sort=ASC&size=-1&page=-1&userId=${userId}`;
        if (submissionId) {
            url += `&submissionId=${submissionId}`;
        }
        const resp = await axios.get<FormSubmissionResponse>(url);
        return resp.data;
    };
}
